<template>
  <div class="ui-grid__empty-result">
    <UILayoutNoTransactions v-if="isNoTransactionsError" />
    <UILayoutNoData v-else v-bind="{ inline }" hide-question-icon>
      <template v-if="!inline" #text-description>
        {{ emptyResultMessage }}
      </template>
    </UILayoutNoData>
  </div>
</template>

<script setup lang="ts">
import { ComputedRef, computed, inject } from 'vue'

import { UILayoutNoData, UILayoutNoTransactions } from '@ui'

defineOptions({
  name: 'GridEmptyResult',
})

const emptyResultMessage = inject<ComputedRef<string | undefined> | undefined>(
  'grid-empty-result-message',
  undefined,
)

const inline = computed(() => !emptyResultMessage?.value)

const isNoTransactionsError = computed(
  () => emptyResultMessage?.value === 'No transactions found',
)
</script>

<style scoped lang="postcss">
.ui-grid__empty-result {
  @apply flex items-center justify-center flex-auto;
  @apply p-4;
}
</style>
