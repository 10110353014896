<template>
  <div class="select-repository-item" :class="mainClasses">
    <div class="select-repository-item__info">
      <div class="select-repository-item__name">{{ item.name }}</div>
      <div class="select-repository-item__owner">
        owned by
        <span class="select-repository-item__owner-name">
          {{ displayOwner }}
        </span>
      </div>
    </div>
    <div v-if="hasCollaborators" class="select-repository-item__colaboration">
      <div
        v-tooltip.top="`${item.collaborators_count} collaborators`"
        class="select-repository-item__collaborators"
      >
        <UserGroupIcon
          aria-hidden="true"
          class="select-repository-item__shared-icon"
        />
        {{ item.collaborators_count }}
      </div>
      <div class="select-repository-item__permission">
        {{ displayPermission }}
      </div>
    </div>
    <SelectRepositoryItemMenu ref="menuRef" :items="menuItems" size="xs" />
  </div>
</template>

<script lang="ts" setup>
import { computed, markRaw, ref, useTemplateRef } from 'vue'

import { DottedMenuItem, Repository } from '@types'

import { ACCESS_TYPES, ACCESS_TYPE_READONLY } from '@/const/repositories'

import { useModalsStore } from '@/store/modals'
import { useUserStore } from '@/store/user'

import RepositorySlideover from '@/views/Repositories/RepositorySlideover.vue'
import {
  UserGroupIcon,
  EyeIcon,
  ArrowUpTrayIcon,
  PencilIcon,
  TrashIcon,
} from '@heroicons/vue/24/outline'
import SelectRepositoryItemMenu from './SelectRepositoryItemMenu.vue'

type Props = {
  item: Repository
  active?: boolean
}

type Emits = {
  delete: [item: Repository]
  export: [id: string, name: string, resolve: () => void, reject: () => void]
}

const props = defineProps<Props>()
const emit = defineEmits<Emits>()

const modalsStore = useModalsStore()
const userStore = useUserStore()

const menuRef = useTemplateRef('menuRef')

const exporting = ref(false)

const hasCollaborators = computed(() => props.item.collaborators_count > 1)

const displayOwner = computed(() => {
  return props.item.owner === userStore.getEmail ? 'me' : props.item.owner
})

const displayPermission = computed(() => {
  if (props.item.settings.readonly) return 'readonly'
  return (
    props.item.user_repo_settings?.access_type &&
    ACCESS_TYPES[props.item.user_repo_settings.access_type - 1]
  )
})

const isReadonly = computed(
  () => props.item?.user_repo_settings?.access_type === ACCESS_TYPE_READONLY,
)

const menuItems = computed<DottedMenuItem[]>(() =>
  isReadonly.value
    ? [
        {
          label: 'View',
          leading: EyeIcon,
          variant: 'secondary',
          fill: 'light',
          action: handleClickEdit,
        },
      ]
    : [
        {
          label: 'Export',
          leading: ArrowUpTrayIcon,
          variant: 'gray',
          fill: 'light',
          loading: exporting.value,
          disabled: exporting.value,
          action: handleClickExport,
        },
        {
          label: 'Edit',
          leading: PencilIcon,
          variant: 'secondary',
          fill: 'light',
          action: handleClickEdit,
        },
        {
          label: 'Delete',
          leading: TrashIcon,
          variant: 'danger',
          fill: 'light',
          action: handleClickDelete,
        },
      ],
)

const mainClasses = computed(() => ({
  'select-repository-item--active': props.active,
}))

const handleClickEdit = async () => {
  const modalInstance = modalsStore.init(
    props.item.id,
    markRaw(RepositorySlideover),
  )

  modalInstance?.open(modalsStore.getZIndex(), {
    repository: props.item,
    wrapped: true,
  })
}

const handleClickDelete = () => {
  menuRef.value?.hide()
  emit('delete', props.item)
}

const handleClickExport = () => {
  exporting.value = true
  emit(
    'export',
    props.item.id,
    props.item.name,
    () => {
      exporting.value = false
    },
    () => {
      exporting.value = false
    },
  )
}
</script>

<style>
.select-repository-item {
  @apply flex items-center justify-between;
  @apply gap-1.5 py-2 pr-1 pl-5;
  @apply rounded;
  @apply bg-inherit hover:bg-body-gray;
  @apply text-gray-400 dark:text-gray-500;
  @apply cursor-pointer;

  .ui-dotted-menu__area {
    @apply -inset-y-1;
    @apply text-body-dark dark:text-body-dark;
  }

  &:hover .ui-dotted-menu__area {
    @apply text-body-gray dark:text-body-gray;
  }

  &--active {
    @apply !bg-indigo-50 dark:!bg-gray-900;
    @apply outline outline-1 outline-indigo-100 dark:outline-gray-800;

    .ui-dotted-menu__area {
      @apply !text-indigo-50 dark:!text-gray-900;
    }
  }

  .ui-slide-panel:is(html:not(.dark) *) & {
    .ui-dotted-menu__area {
      @apply text-body;
    }
  }

  .ui-slide-panel:is(html:not(.dark) *) &:hover {
    .ui-dotted-menu__area {
      @apply text-body-gray;
    }
  }

  &__info {
    @apply flex flex-col flex-auto;
    @apply gap-1;
    @apply overflow-hidden;
  }

  &__name {
    @apply line-clamp-2;
    @apply text-sm;
    @apply font-medium;
    @apply text-light-text;
  }

  &__owner {
    @apply flex items-center;
    @apply gap-x-1;
    @apply text-xs;
    @apply whitespace-nowrap;
  }

  &__owner-name {
    @apply font-medium;
    @apply truncate;
  }

  &--active &__name {
    @apply text-primary dark:text-indigo-400;
  }

  &__shared-icon {
    @apply w-4 h-4;
  }

  &__colaboration {
    @apply flex flex-col items-end shrink-0;
    @apply gap-1 pt-1;
    @apply text-xs;
  }

  &__collaborators {
    @apply flex items-center;
    @apply gap-x-1;
    @apply text-indigo-400;
  }

  &__permission {
    @apply text-[0.6875rem];
    @apply text-gray-400 dark:text-gray-500;
  }
}
</style>
