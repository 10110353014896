import { computed, onMounted, onUnmounted, watch } from 'vue'
import { useActiveElement, useMagicKeys } from '@vueuse/core'

import { HotKeysCallbackObj, HotKeysWatcher } from './utils/types'

import { generateUniqueId, prepareCallbacks } from './utils/helpers'

const { escape, meta, k, q, slash } = useMagicKeys()

const activeElement = useActiveElement()

const callbacks: HotKeysCallbackObj = {}

const isNotInput = computed(
  () =>
    activeElement.value?.tagName !== 'INPUT' &&
    activeElement.value?.tagName !== 'TEXTAREA',
)

watch(escape, value => {
  if (!value) return
  prepareCallbacks(callbacks.escape)?.forEach(cb => cb.fn())
})

watch(
  () => meta.value && k.value,
  value => {
    if (!value || !isNotInput.value) return
    prepareCallbacks(callbacks.search)?.forEach(cb => cb.fn())
  },
)

watch(slash, value => {
  if (!value || !isNotInput.value) return
  prepareCallbacks(callbacks.tree)?.forEach(cb => cb.fn())
})

watch(q, value => {
  if (!value || !isNotInput.value) return
  prepareCallbacks(callbacks.navbar)?.forEach(cb => cb.fn())
})

export const useHotKeys: HotKeysWatcher = (action, fn, params) => {
  const id = generateUniqueId()

  onMounted(() => {
    if (!callbacks[action]) callbacks[action] = []
    callbacks[action].push({
      id,
      fn,
      priority: params?.priority ?? 0,
      flag: params?.flag,
    })
  })

  onUnmounted(() => {
    const index = callbacks[action]?.findIndex(cb => cb.id === id)
    if (index !== undefined && index !== -1) callbacks[action]?.splice(index, 1)
  })
}
