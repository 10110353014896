<template>
  <SelectRepository>
    <template
      #default="{
        items,
        repositoryId,
        repositoryName,
        postfixIcon,
        isActionsAllowed,
        handleClickItem,
        handleExport,
      }"
    >
      <div v-bind="$attrs" @click="handleOpen">
        <RectangleStackIcon
          class="main-mobile-footer-icon"
          aria-hidden="true"
        />
        <component
          :is="postfixIcon"
          v-if="postfixIcon"
          class="main-mobile-footer__status-icon"
          aria-hidden="true"
        />
        <div class="main-mobile-footer-caption">{{ repositoryName }}</div>
      </div>
      <Teleport to="body">
        <UIModal
          v-bind="{ isOpen }"
          :type="ModalType.SLIDEPANEL"
          title="Repositories"
          direction="to-top"
          header-class="main-mobile-footer-repository__header"
          wrapper-class="main-mobile-footer-repository"
          size="sm"
          hide-minimise-button
          @hide="handleClose"
        >
          <div class="main-mobile-footer-repository__list">
            <SelectRepositoryItem
              v-for="item in items"
              :key="item.id"
              v-bind="{ item }"
              :active="repositoryId === item.id"
              @click="handleClose(), handleClickItem(item)"
              @delete="handleDelete"
              @export="handleExport"
            />
          </div>
          <SelectRepositoryActions v-if="isActionsAllowed" />
          <SelectRepositoryLimit v-else />
          <SelectRepositoryDelete
            v-if="deleteRepository"
            :repository="deleteRepository"
            @close="handleCancelDelete"
            @export="handleExport"
          />
        </UIModal>
      </Teleport>
    </template>
  </SelectRepository>
</template>

<script setup lang="ts">
import { ref } from 'vue'

import { ModalType, Repository } from '@types'

import SelectRepository from './components/SelectRepository.vue'
import SelectRepositoryActions from './components/SelectRepositoryActions.vue'
import SelectRepositoryDelete from './components/SelectRepositoryDelete.vue'
import SelectRepositoryItem from './components/SelectRepositoryItem.vue'
import SelectRepositoryLimit from './components/SelectRepositoryLimit.vue'
import { RectangleStackIcon } from '@heroicons/vue/24/outline'
import { UIModal } from '@ui'

const isOpen = ref(false)

const deleteRepository = ref<Repository>()

const handleOpen = () => {
  isOpen.value = true
}

const handleClose = () => {
  isOpen.value = false
}

const handleDelete = (item: Repository) => {
  deleteRepository.value = item
}

const handleCancelDelete = () => {
  deleteRepository.value = undefined
}
</script>

<style>
.main-mobile-footer-repository {
  @apply !px-0 pb-4;

  .select-repository-item__name {
    @apply w-auto;
  }

  &__header {
    @apply !px-6;
  }

  &__list {
    @apply flex-auto;
    @apply pr-4 pl-3;
    @apply overflow-y-auto;
  }

  .select-repository-item {
    @apply pr-2 pl-3;
  }
}
</style>
