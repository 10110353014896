<template>
  <div class="ui-button-icon group" :class="mainClasses" @click="handleClick">
    <UIButton v-bind="{ ...$attrs, size, disabled }" ref="buttonRef">
      <template v-for="(_, name) in buttonSlots" #[name]="scope">
        <slot :name="name" v-bind="scope" />
      </template>
    </UIButton>
    <div
      v-if="label || $slots.default"
      class="ui-button-icon__label"
      :class="labelClasses"
    >
      <slot>{{ label }}</slot>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, useSlots, useTemplateRef } from 'vue'

import { UIButton } from '@ui'

import { ButtonSize } from '@types'

type Props = {
  class?: any
  label?: string
  size?: ButtonSize
  disabled?: boolean
}

type Emits = {
  click: [event: MouseEvent]
}

const {
  label,
  size = 'default',
  class: className,
  disabled,
} = defineProps<Props>()

const emit = defineEmits<Emits>()

defineOptions({ inheritAttrs: false })

const slots = useSlots() as any

const buttonRef = useTemplateRef('buttonRef')

defineExpose({
  focus() {
    buttonRef.value?.focus()
  },
})

const mainClasses = computed(() => [
  className,
  {
    [`ui-button-icon--${size}`]: true,
    'ui-button-icon--disabled': disabled,
  },
])

const labelClasses = computed(() => ({
  [`ui-button-icon__label--${size}`]: true,
  'ui-button-icon__label--disabled': disabled,
}))

const buttonSlots = computed(() =>
  Object.fromEntries(
    Object.entries(slots).filter(([key]) => key !== 'default'),
  ),
)

const handleClick = (event: MouseEvent) => {
  emit('click', event)
}
</script>

<style>
.ui-button-icon {
  @apply flex flex-col items-center;
  @apply gap-2;
  @apply shrink-0;
  @apply cursor-pointer;

  &--xs,
  &--sm {
    @apply gap-1;
  }

  &--disabled {
    @apply cursor-default;
  }

  .ui-button {
    @apply aspect-square;
    @apply p-0;
  }

  .ui-button__icon--xs {
    @apply w-4;
  }

  .ui-button__icon--sm {
    @apply w-5;
  }

  .ui-button__icon--default {
    @apply w-6;
  }

  .ui-button__icon--lg {
    @apply w-7;
  }

  .ui-button__icon--xl {
    @apply w-8;
  }

  .ui-button__icon--2xl {
    @apply w-9;
  }

  &__label {
    @apply text-sm leading-tight;
    @apply text-center;

    &--disabled {
      @apply opacity-50;
    }

    &--xs,
    &--sm {
      @apply text-[0.625rem];
    }

    &--xs {
      @apply max-w-10;
    }

    &--sm {
      @apply max-w-16;
    }

    &--default {
      @apply max-w-20;
    }

    &--lg {
      @apply max-w-32;
    }

    &--xl,
    &--2xl {
      @apply max-w-40;
      @apply text-sm leading-tight;
    }
  }
}
</style>
