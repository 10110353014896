import { ROUTE_NAME } from '@/const'

import { NavigationPreset } from './types'

import {
  ArrowDownCircleIcon,
  ArrowUpCircleIcon,
  ChartBarIcon,
  ChartBarSquareIcon,
  ChartPieIcon,
  CurrencyDollarIcon,
  EnvelopeIcon,
  FolderOpenIcon,
  FunnelIcon,
  SwatchIcon,
  WalletIcon,
  CircleStackIcon,
  ArrowsRightLeftIcon,
} from '@heroicons/vue/24/outline'
import { LinkIcon } from '@heroicons/vue/20/solid'

export const DASHBOARD_NAME = 'Dashboards'
export const CONNECTOR_NAME = 'Connectors'

export const PARENT_NAVIGATION: NavigationPreset[] = [
  {
    label: DASHBOARD_NAME,
    icon: ChartPieIcon,
    route: ROUTE_NAME.DASHBOARDS,
  },
  {
    label: 'Data',
    icon: CircleStackIcon,
  },
  {
    label: CONNECTOR_NAME,
    icon: LinkIcon,
  },
  {
    label: 'Analytics',
    icon: ChartBarSquareIcon,
  },
]

export const CHILDREN_NAVIGATION: Record<string, NavigationPreset[]> = {
  Data: [
    {
      label: 'Transactions',
      icon: ArrowsRightLeftIcon,
      route: ROUTE_NAME.TRANSACTIONS,
      additional: [ROUTE_NAME.TRANSACTIONS_ITEM],
    },
    {
      label: 'Assets',
      icon: ChartBarIcon,
      route: ROUTE_NAME.ASSETS,
      additional: Object.entries(ROUTE_NAME)
        .filter(([key]) => key.startsWith('ASSETS_ITEM'))
        .map(([, value]) => value),
    },
    {
      label: 'Tags',
      icon: SwatchIcon,
      route: ROUTE_NAME.TAGS,
      additional: [ROUTE_NAME.TAGS_ITEM],
    },
    {
      label: 'Income accounts',
      icon: ArrowUpCircleIcon,
      route: ROUTE_NAME.INCOME_ACCOUNTS,
      additional: [ROUTE_NAME.INCOME_ACCOUNTS_ITEM],
    },
    {
      label: 'Expense accounts',
      icon: ArrowDownCircleIcon,
      route: ROUTE_NAME.EXPENSE_ACCOUNTS,
      additional: [ROUTE_NAME.EXPENSE_ACCOUNTS_ITEM],
    },
    {
      label: 'Currencies',
      icon: CurrencyDollarIcon,
      route: ROUTE_NAME.CURRENCIES,
      additional: [ROUTE_NAME.CURRENCIES_ITEM],
    },
    {
      label: 'Documents',
      icon: FolderOpenIcon,
      route: ROUTE_NAME.DOCUMENTS,
      additional: [ROUTE_NAME.DOCUMENTS_ITEM],
    },
    {
      label: 'Contacts',
      icon: EnvelopeIcon,
      route: ROUTE_NAME.CONTACTS,
      additional: [ROUTE_NAME.CONTACTS_ITEM],
    },
  ],
  Connectors: [
    {
      label: 'Accounts',
      icon: WalletIcon,
      route: ROUTE_NAME.LINKED_DATA,
      additional: [
        ROUTE_NAME.LINKED_DATA_ACCOUNT,
        ROUTE_NAME.LINKED_DATA_ACCOUNT_INFO,
        ROUTE_NAME.LINKED_DATA_ACCOUNT_POSITIONS,
        ROUTE_NAME.LINKED_DATA_CONNECTOR,
      ],
    },
    {
      label: 'Transactions',
      icon: ArrowsRightLeftIcon,
      route: ROUTE_NAME.LINKED_DATA_TRANSACTIONS,
    },
    {
      label: 'Mapping rules',
      icon: FunnelIcon,
      route: ROUTE_NAME.LINKED_DATA_MAPPING_RULES,
    },
  ],
  Analytics: [
    {
      label: 'Value',
      route: ROUTE_NAME.ANALYTICS_VALUE,
    },
    {
      label: 'Value Change',
      route: ROUTE_NAME.ANALYTICS_VALUE_CHANGE,
    },
    {
      label: 'Income',
      route: ROUTE_NAME.ANALYTICS_INCOME,
    },
    {
      label: 'Position',
      route: ROUTE_NAME.ANALYTICS_POSITION,
    },
  ],
}
