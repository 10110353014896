<template>
  <UIDottedMenu
    v-if="menuMode && items.length"
    :items
    size="xxs"
    :meta="{ key: item.key }"
  />
  <UIDropdown
    v-else-if="!menuMode"
    v-bind="{ triggers }"
    ref="dropdownRef"
    :disabled="isDropdownDisabled"
  >
    <span class="main-view-tab-dropdown__caption" :class="textClasses">
      <slot v-bind="{ disabled }" />
    </span>
    <template #popper="{ hide: callback }">
      <div class="main-view-tab-dropdown">
        <div
          v-for="el in items"
          :key="el.tooltip"
          class="ui-dropdown__item"
          :class="
            el.variant === 'danger' ? 'ui-dropdown__item--danger' : undefined
          "
          @click="el.action?.({ callback, key: item.key })"
        >
          <component
            :is="el.leading"
            class="ui-dropdown__item-icon main-view-tab-dropdown__icon"
          />
          <span class="ui-dropdown__item-text main-view-tab-dropdown__text">
            {{ el.tooltip }}
          </span>
        </div>
      </div>
    </template>
  </UIDropdown>
</template>

<script setup lang="ts">
import { computed, useTemplateRef } from 'vue'

import { DottedMenuItem, NavigationItem } from '@types'

import { UIDottedMenu, UIDropdown } from '@ui'
import { PencilIcon } from '@heroicons/vue/24/outline'

type Props = {
  item: NavigationItem
  disabled?: boolean
  triggers?: string[]
  container?: string
  menuMode?: boolean
}

type Emits = {
  edit: []
  close: []
}

const props = defineProps<Props>()
const emit = defineEmits<Emits>()

defineExpose({
  show: () => dropdownRef.value?.show(),
  hide: () => dropdownRef.value?.hide(),
})

const dropdownRef = useTemplateRef('dropdownRef')

const isDropdownDisabled = computed(() => props.disabled || !items.value.length)

const textClasses = computed(() => ({
  'main-view-tab-dropdown__caption--active':
    props.item.active && items.value.length,
  'main-view-tab-dropdown__caption--disabled': props.disabled,
}))

const items = computed(() => {
  const result: DottedMenuItem[] = []
  props.item.editable &&
    result.push({
      tooltip: 'Rename',
      leading: PencilIcon,
      variant: 'gray',
      fill: 'light',
      action: ({ callback }) => {
        emit('edit')
        callback?.()
      },
    })
  props.item.actions && result.push(...props.item.actions)
  return result
})
</script>

<style>
.main-view-tab-dropdown {
  @apply min-w-32;
  @apply py-1;

  &__caption {
    @apply rounded-sm;

    &--active:not(&--disabled) {
      @apply hover:outline hover:outline-1 hover:outline-offset-2;
      @apply hover:outline-indigo-100 hover:dark:outline-gray-600;
      @apply cursor-pointer;
    }
  }

  &__text {
    @apply text-xs;
  }

  &__icon {
    @apply w-3 aspect-square;
  }
}
</style>
