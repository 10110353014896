<template>
  <UIDottedMenu
    v-if="isSlotFilled || items?.length"
    :items
    size="xs"
    :direction
    :meta="{ item }"
    class="ui-grid__actions"
    :class="mainClasses"
    @mouseup.stop
    @mousedown.stop
  >
    <slot v-if="isSlotFilled" />
  </UIDottedMenu>
</template>

<script setup lang="ts" generic="T">
import { Ref, computed, inject, useSlots } from 'vue'

import { DottedMenuItem } from '@types'

import { UIDottedMenu } from '@ui'

type Props = {
  items?: DottedMenuItem[]
  item?: T
}

defineProps<Props>()

defineOptions({
  name: 'RowCellActions',
})

const slots = useSlots()

const isHovered = inject<Ref<boolean>>('isHovered')
const isCollapsed = inject<Ref<boolean>>('isCollapsed')

const isSlotFilled = computed(() => slots.default?.({})?.[0]?.children?.length)

const direction = computed(() => (isCollapsed?.value ? 'left' : 'right'))

const mainClasses = computed(() => ({
  'ui-grid__actions--hovered': isHovered?.value,
}))
</script>

<style>
.ui-grid__actions {
  @apply !absolute !left-1;
  @apply z-10;

  .ui-dotted-menu__area {
    @apply text-body;
  }

  &--hovered {
    .ui-dotted-menu__area {
      @apply text-body-gray;
    }
  }
}

.ui-grid:is(.ui-grid--collapsed) {
  .ui-grid__actions {
    @apply !left-auto !right-1;
  }
  .ui-dotted-menu__area {
    @apply text-body;
  }
}
</style>
