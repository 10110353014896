<template>
  <UICheckbox v-model="modelValue" @change="handleChange" />
</template>

<script setup lang="ts">
import { UICheckbox } from '@ui'

type Emits = {
  change: [data: boolean]
}

const emit = defineEmits<Emits>()

defineOptions({
  name: 'GridCheckbox',
})

const modelValue = defineModel<(string | number)[] | boolean>()

const handleChange = (flag: boolean) => {
  emit('change', flag)
}
</script>
