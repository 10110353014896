<template>
  <div class="ui-progress-bar">
    <div
      class="ui-progress-bar__state"
      :class="stateClasses"
      :style="stateStyles"
    />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

type Props = {
  progress?: number
}

const { progress = 0 } = defineProps<Props>()

const stateClasses = computed(() => ({
  'ui-progress-bar__state--active': progress > 0,
  'ui-progress-bar__state--filled': progress === 100,
}))

const stateStyles = computed(() => ({
  width: `${progress}%`,
}))
</script>

<style>
.ui-progress-bar {
  &__state {
    @apply h-1;
    @apply bg-primary-selected;

    &--active {
      @apply transition-all;
    }

    &--filled {
      @apply opacity-0;
    }
  }
}
</style>
