import { Asset, RequireBy, UserPlan } from '@types'

import { ANALYTICS_PERIOD_NAMING } from '@/store/analytics/utils/const'

export type RepositoryTimeline = {
  periods: number
  freq: keyof typeof ANALYTICS_PERIOD_NAMING
  end: string
  include_end: boolean
  end_is_today: boolean
}

export type RepositoryUISettings = Record<string, any> | null

export type RepositorySettings = {
  price_source_precedence: string[]
  init_default_tags: boolean
  readonly: boolean
}

export type UserRepositorySettings = RepositoryTimeline & {
  user_id: number
  access_type?: number
  ui_settings: RepositoryUISettings
}

export type UserRepositorySettingsPost = Omit<UserRepositorySettings, 'user_id'>
export type UserRepositorySettingsPut = UserRepositorySettings
export type UserRepositorySettingsPatch = Partial<
  Omit<UserRepositorySettings, 'user_id'>
>

export type Repository = {
  id: string
  name: string
  currency: string
  collaborators_count: number
  currency_asset?: Asset | null
  plan: UserPlan
  owner: string
  settings: RepositorySettings
  user_repo_settings: UserRepositorySettings
}

export type RepositoryPost = Omit<Repository, 'id' | 'user_repo_settings'> & {
  user_repo_settings: UserRepositorySettingsPost
}

export type RepositoryPut = Pick<Repository, 'id'> &
  Partial<Omit<Repository, 'id'>>
export type RepositoryPatch = RequireBy<
  Repository,
  'id' | 'user_repo_settings'
> & {
  user_repo_settings: UserRepositorySettingsPatch
}

export type RepositoryModel = Partial<RepositoryPut> & RepositoryPost

export enum RepositoryStatus {
  PENDING = 'pending',
  ACCEPTED = 'accepted',
  DECLINED = 'declined',
  OWNER = '',
}

export type RepositoryInvite = {
  id: string
  email: string
  access_type: number
  status: RepositoryStatus
  repository: { id: string; name: string }
  inviter: { id: string; email: string }
  created_at: string
  updated_at: string
  accepted?: boolean
}

export type RepositoryInvitePost = Pick<
  RepositoryInvite,
  'email' | 'access_type'
> & { repository_id: string }

export type RepositoryCollaborator = {
  email: string
  access_type: number
  status: RepositoryStatus
  repository: { id: string; name: string }
  user: { id: string; email: string }
}

export type Currency = {
  name: string
  ticker: string
}
