<template>
  <UIDottedMenu
    ref="menuRef"
    :items
    :size
    class="select-repository-item-menu"
  />
</template>

<script lang="ts" setup>
import { useTemplateRef } from 'vue'

import { ButtonSize, DottedMenuItem } from '@types'

import { UIDottedMenu } from '@ui'

type Props = {
  items: DottedMenuItem[]
  size?: ButtonSize
}

defineProps<Props>()

defineExpose({
  hide() {
    menuRef.value?.hide()
  },
})

const menuRef = useTemplateRef('menuRef')
</script>

<style scoped>
.select-repository-item-menu {
  @apply -my-1;
}
</style>
