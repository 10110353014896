<template>
  <NavbarItem :item>
    <template #default="{ items, current }">
      <UIDropdown
        v-bind="{ items }"
        v-model:show="isShown"
        :model-value="current"
        :show-delay="300"
        :disabled
        id-key="key"
        value-key="label"
        placement="left-start"
        :show-triggers="['hover']"
        :hide-triggers="['hover']"
        :popper-show-triggers="['hover']"
        :popper-hide-triggers="['hover']"
        popper-class="navbar-item-menu__dropdown"
        @click:item="handleClick"
      >
        <component
          :is="disabled ? 'div' : 'RouterLink'"
          :to="
            disabled
              ? undefined
              : { name: item.route, params: item.routeParams }
          "
          :data-refid="dataRefId"
        >
          <UIButtonIcon
            :label="item.label"
            :leading="item.icon"
            :variant="item.active ? 'secondary' : 'gray'"
            :fill="item.active ? undefined : 'outlined'"
            :disabled
            size="sm"
            class="navbar-item-menu"
          />
        </component>
        <template #before>
          <div class="navbar-item-menu__caption">{{ item.label }}</div>
        </template>
        <template #item="{ item: child }">
          <NavbarItemMenuChild :child />
        </template>
      </UIDropdown>
    </template>
  </NavbarItem>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { upperFirst } from 'lodash'
import { useRouter } from 'vue-router'

import { NavigationItem } from '@types'

import NavbarItem from './NavbarItem.vue'
import NavbarItemMenuChild from './NavbarItemMenuChild.vue'
import { UIButtonIcon, UIDropdown } from '@ui'

type Props = {
  item: NavigationItem
  disabled?: boolean
}

const props = defineProps<Props>()

const router = useRouter()

const isShown = ref(false)

const dataRefId = computed(
  () =>
    `panelMenuItem${props.item.label
      ?.split(' ')
      .map(item => upperFirst(item))
      .join('')}`,
)

const handleClick = (data: NavigationItem) => {
  router.push({ name: data.route, params: data.routeParams })
}
</script>

<style>
.navbar-item-menu {
  @apply relative;
  @apply py-1 px-0.5;
  @apply rounded-l-md;
  @apply border border-r-0 border-transparent;

  .ui-button-icon__label {
    @apply max-w-none;
  }

  &__caption {
    @apply py-2 px-4;
    @apply text-xs font-medium uppercase;
    @apply text-gray-400;
  }

  &__dropdown .ui-dropdown__items {
    @apply !max-h-full;
  }

  &__dropdown .ui-dropdown__item {
    @apply items-center;
  }

  &__dropdown .ui-dropdown__item-text {
    @apply flex-auto;
  }
}
</style>
