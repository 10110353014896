<template>
  <div>
    <span v-if="item.active || disabled" class="main-desktop-tabs-item">
      <slot />
    </span>
    <router-link
      v-else
      :to="{ name: item.route, params: item.routeParams }"
      :data-refid="dataRefId"
      class="main-desktop-tabs-item"
    >
      <slot />
    </router-link>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { upperFirst } from 'lodash'

import { NavigationItem } from '@types'

type Props = {
  item: NavigationItem
  disabled?: boolean
  dataRefId?: string
}

const props = defineProps<Props>()

const dataRefId = computed(() =>
  props.dataRefId
    ? `${props.dataRefId}${props.item.label
        ?.split(' ')
        .map(item => upperFirst(item))
        .join('')}`
    : undefined,
)
</script>

<style>
.main-desktop-tabs-item {
  @apply flex items-center;
}
</style>
