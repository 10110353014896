<template>
  <div
    v-if="isNavbarOpened"
    class="navbar-node"
    :class="{ 'navbar-node--active': isTreeOpened }"
    @click="handleToggle"
  >
    <div class="navbar-node__icon">
      <TreeIcon aria-hidden="true" />
    </div>
    <div class="navbar-node__label">{{ t('Asset tree') }}</div>
    <UIButtonIcon
      :trailing="isTreeOpened ? ChevronDoubleLeftIcon : ChevronDoubleRightIcon"
      variant="gray"
      size="xxs"
      fill="light"
    />
    <template v-if="isTreeOpened">
      <div class="navbar-item__arrow navbar-item__arrow--top" />
      <div class="navbar-item__arrow navbar-item__arrow--bottom" />
    </template>
  </div>
  <UIButtonIcon
    v-else
    :leading="TreeIcon"
    :variant="isTreeOpened ? 'secondary' : 'gray'"
    :fill="isTreeOpened ? undefined : 'outlined'"
    size="sm"
    class="navbar-item-menu"
    :class="{ 'navbar-item--active': isTreeOpened }"
    @click="handleToggle"
  >
    {{ t('Asset tree') }}
    <template v-if="isTreeOpened">
      <div class="navbar-item__arrow navbar-item__arrow--top" />
      <div class="navbar-item__arrow navbar-item__arrow--bottom" />
    </template>
  </UIButtonIcon>
</template>

<script setup lang="ts">
import { inject } from 'vue'

import { OpenedFlag } from './utils/type'

import { NAVBAR_OPENED, REPOSITORIES_OPENED, TREE_OPENED } from './utils/const'

import { useLocale } from '@/plugins/i18n'
import { useHotKeys } from '@/plugins/hotKeys'

import TreeIcon from '@/assets/images/icons/tree.svg'
import { UIButtonIcon } from '@ui'
import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
} from '@heroicons/vue/24/outline'

const { t } = useLocale('layouts.Main')

const isNavbarOpened = inject<OpenedFlag>(NAVBAR_OPENED)
const isRepositoryOpened = inject<OpenedFlag>(REPOSITORIES_OPENED)
const isTreeOpened = inject<OpenedFlag>(TREE_OPENED)

const handleToggle = () => {
  isRepositoryOpened && (isRepositoryOpened.value = false)
  isTreeOpened && (isTreeOpened.value = !isTreeOpened.value)
}

useHotKeys('tree', handleToggle)
</script>
