<template>
  <div class="select-repository-actions">
    <UIButton
      data-refid="repositoryImport"
      label="Import"
      :disabled
      :size
      :leading="ArrowDownTrayIcon"
      variant="secondary"
      @click="handleImport"
    />
    <UIButton
      data-refid="repositoryCreate"
      :disabled
      :size
      :leading="PlusIcon"
      label="New"
      @click="handleCreate"
    />
  </div>
</template>

<script lang="ts" setup>
import { markRaw } from 'vue'

import { ButtonSize } from '@types'

import { useModalsStore } from '@/store/modals'

import RepositoryImportDialog from '@/views/Repositories/RepositoryImportDialog.vue'
import RepositorySlideover from '@/views/Repositories/RepositorySlideover.vue'
import { ArrowDownTrayIcon, PlusIcon } from '@heroicons/vue/24/outline'
import { UIButton } from '@ui'

type Props = {
  disabled?: boolean
  size?: ButtonSize
}

type Emits = {
  click: []
}

defineProps<Props>()
const emit = defineEmits<Emits>()

const modalsStore = useModalsStore()

const handleImport = () => {
  emit('click')
  const modalInstance = modalsStore.init(
    'repository-import',
    markRaw(RepositoryImportDialog),
  )
  modalInstance?.open(modalsStore.getZIndex())
}

const handleCreate = () => {
  emit('click')
  const modalInstance = modalsStore.init(
    'repository-create',
    markRaw(RepositorySlideover),
  )
  modalInstance?.open(modalsStore.getZIndex(), {
    focusOnLoad: true,
  })
}
</script>

<style scoped>
.select-repository-actions {
  @apply flex items-center justify-between;
  @apply gap-2;
  @apply px-6 py-4 md:p-0;

  :deep() .ui-button {
    @apply whitespace-nowrap;
  }
}
</style>
