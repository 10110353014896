import { FilterComparison, FilterLogic, Widget } from '@types'

import { LINKED_TRANSACTION_FIELDS } from '@/views/LinkedData/Transactions/utils/const'
import { DEFAULT_COLS } from '@/views/Dashboards/Dashboard/Widgets/LinkedTransactions/utils/const'

import { generateRandomKey } from '@/helpers'

export const getDefaultLinkedTransactionsAttrs = (): Widget['attrs'] => ({
  count: 15,
  filter: {
    params: [
      {
        key: generateRandomKey(),
        params: [
          {
            key: generateRandomKey(),
            comparison: FilterComparison.NOTCONTAINS,
            field: LINKED_TRANSACTION_FIELDS.TAGS.field,
            value: 'exclude',
          },
        ],
        logic: FilterLogic.AND,
      },
    ],
    logic: FilterLogic.AND,
  },
  cols: DEFAULT_COLS,
})
