<template>
  <div class="navbar-container">
    <slot />
    <div class="navbar-container__toggler">
      <UIButtonPanel
        v-tooltip.top="tooltip"
        :model-value="true"
        variant="gray"
        @click="handleTogglerClick"
      />
      <div
        class="navbar-container__toggler-arrow navbar-container__toggler-arrow--top"
      />
      <div
        class="navbar-container__toggler-arrow navbar-container__toggler-arrow--bottom"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { inject } from 'vue'

import { OpenedFlag } from '../utils/type'

import { REPOSITORIES_OPENED, TREE_OPENED } from '../utils/const'
import { UIButtonPanel } from '@ui'

type Props = {
  tooltip?: string
}

defineProps<Props>()

const isTreeOpened = inject<OpenedFlag>(TREE_OPENED)
const isRepositoriesOpened = inject<OpenedFlag>(REPOSITORIES_OPENED)

const handleTogglerClick = () => {
  isTreeOpened!.value = false
  isRepositoriesOpened!.value = false
}
</script>

<style>
.navbar-container {
  @apply w-[22rem];
  @apply relative;
  @apply flex flex-col shrink-0;
  @apply bg-body-dark;
  @apply border-r border-common-border;

  &__toggler {
    @apply absolute top-[.7rem] left-full;
    @apply bg-body-dark;
    @apply border border-l-0 border-common-border;
    @apply rounded-r;
    @apply z-10;

    .ui-button {
      @apply -ml-1;
      @apply hover:bg-transparent;
      @apply z-[11];
    }
  }

  &__toggler-arrow {
    @apply absolute left-0;
    @apply size-2;
    @apply bg-body-dark;

    @apply before:content-[''];
    @apply before:absolute before:inset-0;
    @apply before:bg-body;
    @apply before:border before:border-common-border before:border-r-0;

    &--top {
      @apply bottom-full;
      @apply before:border-t-0;
      @apply before:rounded-es;
    }

    &--bottom {
      @apply top-full;
      @apply before:border-b-0;
      @apply before:rounded-ss;
    }
  }
}
</style>
