<template>
  <div class="ui-tree__search">
    <div class="ui-tree__search-input">
      <UIPanelSearch
        v-model="search"
        v-model:storage="modelValue"
        v-bind="{ placeholder, size }"
        clearable
        @keydown="handleKeyDown"
      />
    </div>
    <template v-if="search">
      <UIPreloader v-if="isLoading" />
      <template v-else>
        <span class="ui-tree__search-result">
          {{ displayNumberFound }}
        </span>
        <template v-if="numberFound > 1">
          <UIButtonIcon
            :leading="ArrowUpIcon"
            :size="buttonSize"
            variant="gray"
            fill="outlined"
            class="ui-tree__search-arrow"
            @click="handleClickUp"
          />
          <UIButtonIcon
            :leading="ArrowDownIcon"
            :size="buttonSize"
            variant="gray"
            fill="outlined"
            class="ui-tree__search-arrow"
            @click="handleClickDown"
          />
        </template>
      </template>
    </template>
  </div>
</template>

<script setup lang="ts">
import { Ref, computed, inject, ref } from 'vue'

import { scrollToNode } from '../utils/helpers'

import { UIPreloader, UIPanelSearch, UIButtonIcon } from '@ui'
import { ArrowUpIcon, ArrowDownIcon } from '@heroicons/vue/24/outline'
import { InputSize } from '../../Input/utils/types'
import { ButtonSize } from '@types'

type Props = {
  numberFound: number

  isLoading: boolean

  placeholder?: string

  size?: InputSize
}

const props = defineProps<Props>()

const modelValue = defineModel<string>({ default: '' })
const search = defineModel<string>('search', { default: '' })

const highlightedNodeIndex = inject<Ref<number>>('highlightedNodeIndex', ref(0))

const buttonSize = computed<ButtonSize>(() => {
  switch (props.size) {
    case 'small':
      return 'sm'
    case 'xsmall':
      return 'xs'
    case 'xxsmall':
      return 'xxs'
    case 'large':
      return 'lg'
    case 'xlarge':
      return 'xl'
    default:
      return 'default'
  }
})

const displayNumberFound = computed(
  () =>
    props.numberFound +
    ' result' +
    (props.numberFound > 1 || !props.numberFound ? 's' : ''),
)

const handleClickUp = () => {
  highlightedNodeIndex.value--
  if (highlightedNodeIndex.value < 1) {
    highlightedNodeIndex.value = props.numberFound
  }
  scrollToCurrentNode()
}

const handleClickDown = () => {
  highlightedNodeIndex.value++
  if (highlightedNodeIndex.value > props.numberFound) {
    highlightedNodeIndex.value = 1
  }
  scrollToCurrentNode()
}

const handleKeyDown = (e: KeyboardEvent) => {
  if (e.key === 'Enter' && !e.shiftKey) {
    handleClickDown()
  }
  if (e.key === 'Enter' && e.shiftKey) {
    handleClickUp()
  }
}

const scrollToCurrentNode = () => {
  scrollToNode(highlightedNodeIndex.value)
}
</script>

<style>
.ui-tree {
  &__search {
    @apply flex items-center justify-between;
    @apply shrink-0;
    @apply gap-x-2;
    @apply mb-2;
  }

  &__search-input {
    @apply w-full;
  }

  &__search-result {
    @apply flex items-center;
    @apply whitespace-nowrap;
    @apply text-gray-400;
    @apply text-xs;
  }

  &__search-arrow {
    .ui-button {
      @apply focus:ring-offset-1 focus:ring-offset-body-gray;
    }
  }
}
</style>
