import { generateRGBPalette } from '../helpers'

export default {
  color: generateRGBPalette('#416DE6', '#BAC8F3', 8),
  textStyle: {},
  title: {
    textStyle: {
      color: '#6b7280',
    },
    subtextStyle: {
      color: '#6b7280',
    },
  },
  label: {
    color: '#BCBCBC',
    rich: {
      value: {
        fontSize: 10,
        color: '#8A929F',
      },
    },
  },
  legend: {
    textStyle: {
      color: '#8A929F',
    },
  },
  tooltip: {
    backgroundColor: '#1f2937',
    textStyle: {
      color: '#8A929F',
    },
  },
  bar: {
    select: {
      itemStyle: {
        color: '#a5b4fc',
        borderWidth: 0,
        shadowBlur: 8,
        shadowColor: 'rgba(255, 255, 255, 0.5)',
        shadowOffsetY: 2,
      },
    },
  },
  pie: {
    itemStyle: {
      borderWidth: 1,
      borderColor: '#1f2937',
    },
  },
  categoryAxis: {
    axisLine: {
      show: true,
      lineStyle: {
        color: '#BCBCBC',
      },
    },
    axisTick: {
      show: true,
      lineStyle: {
        color: '#8A929F',
      },
    },
    axisLabel: {
      show: true,
      color: '#BCBCBC',
    },
  },
  valueAxis: {
    axisLine: {
      show: false,
    },
    axisTick: {
      show: false,
    },
    axisLabel: {
      show: true,
      color: '#8A929F',
    },
    splitLine: {
      show: true,
      lineStyle: {
        color: ['#8A929F', '#8A929F'],
      },
    },
  },
}
