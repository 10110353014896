<template>
  <div class="select-repository-delete">
    <div class="select-repository-delete__text">
      Are you sure you want to delete
      <span class="select-repository-delete__name">
        {{ displayName }}
      </span>
      repository?
    </div>
    <div class="select-repository-delete__actions">
      <UICheckbox
        v-model="enableDelete"
        :disabled="processing"
        label="Please check to confirm delete"
        data-refid="disableDelete"
      />
      <UICheckbox
        v-if="enableDelete"
        v-model="exportBeforeDelete"
        :disabled="processing"
        label="Export before deleting"
        data-refid="exportBeforeDelete"
      />
    </div>
    <div class="select-repository-delete__buttons">
      <UIButton
        :disabled="processing"
        label="Cancel"
        variant="secondary"
        fill="outlined"
        @click="handleCancel"
      />
      <UIButton
        v-bind="{ disabled }"
        :loading="processing"
        label="Delete"
        variant="danger"
        @click="handleConfirm"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue'

import { Repository } from '@types'

import { NOTIFICATION_DELAY } from '@/const'

import { handleCatchedError } from '@/helpers/common'

import { useNotifications } from '@/plugins/notification'

import { useRepositoriesStore } from '@/store/repositories'

import { UIButton, UICheckbox } from '@ui'

type Props = {
  repository: Repository
}

type Emits = {
  close: []
  export: [id: string, name: string, resolve: () => void, reject: () => void]
}

const props = defineProps<Props>()
const emit = defineEmits<Emits>()

const repositoriesStore = useRepositoriesStore()

const { success } = useNotifications()

const enableDelete = ref(false)
const exportBeforeDelete = ref(true)

const processing = ref(false)

const disabled = computed(() => processing.value || !enableDelete.value)

const displayName = computed(() => props.repository.name)

const handleCancel = () => {
  emit('close')
}

const handleConfirm = async () => {
  try {
    processing.value = true
    if (exportBeforeDelete.value) {
      await new Promise((resolve, reject) => {
        emit(
          'export',
          props.repository.id,
          displayName.value,
          () => resolve(undefined),
          () => reject(),
        )
      })
    }
    await repositoriesStore.deleteRepository(props.repository.id, false)
    await repositoriesStore.fetchRepositories()
    success(
      {
        message: `${displayName.value} repository deleted`,
      },
      NOTIFICATION_DELAY,
    )
  } catch (e) {
    handleCatchedError(e as string, { id: props.repository.id })
    processing.value = false
  } finally {
    emit('close')
  }
}
</script>

<style>
.select-repository-delete {
  @apply absolute inset-0;
  @apply flex flex-col items-center;
  @apply pt-20 px-4 gap-4;
  @apply bg-body-dark bg-opacity-90;
  @apply text-sm text-common-text;

  &__text {
    @apply text-center;
  }

  &__name {
    @apply text-primary-active;
  }

  &__actions {
    @apply flex flex-col;
    @apply gap-y-2;
  }

  &__buttons {
    @apply flex items-center;
    @apply gap-x-2;
  }
}
</style>
