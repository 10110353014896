import { DashboardsItem, DashboardWidgetName, UserPlanName } from '@types'
import {
  Bars4Icon,
  ChartPieIcon,
  RectangleGroupIcon,
  TableCellsIcon,
} from '@heroicons/vue/24/outline'
import { getDefaultLinkedTransactionsAttrs } from './helpers'

export const DASHBOARD_VIEWS = {
  desktop: {
    portfolio: {
      name: 'Portfolio',
      slug: 'portfolio',
      icon: ChartPieIcon,
      widgets: [
        {
          id: DashboardWidgetName.ValueHistoryPieChart,
          name: DashboardWidgetName.ValueHistoryPieChart,
          title: 'Value Pie Chart',
          attrs: {},
        },
        {
          id: DashboardWidgetName.ValueHistoryTable,
          name: DashboardWidgetName.ValueHistoryTable,
          title: 'Value Table',
          attrs: {},
          tall: true,
        },
        {
          id: DashboardWidgetName.ValueHistoryBarsChart,
          name: DashboardWidgetName.ValueHistoryBarsChart,
          title: 'Value History',
          attrs: {},
        },
        {
          id: DashboardWidgetName.ValueChangeSnapshot,
          name: DashboardWidgetName.ValueChangeSnapshot,
          title: 'Value Change',
          attrs: {},
          wide: true,
        },
      ],
    },
    valuePivot: {
      name: 'Value Pivot',
      slug: 'value-pivot',
      icon: RectangleGroupIcon,
      widgets: [
        {
          id: DashboardWidgetName.ValuePivot,
          name: DashboardWidgetName.ValuePivot,
          title: '',
          attrs: {},
          tall: true,
          wide: true,
        },
      ],
    },
    linkedTransactions: {
      name: 'Linked transactions',
      slug: 'linked-transactions',
      icon: Bars4Icon,
      widgets: [
        {
          id: DashboardWidgetName.LinkedTransactions,
          name: DashboardWidgetName.LinkedTransactions,
          title: 'Most recent linked transactions',
          attrs: getDefaultLinkedTransactionsAttrs(),
          wide: true,
        },
        {
          id: DashboardWidgetName.LinkedTransactionsPivot,
          name: DashboardWidgetName.LinkedTransactionsPivot,
          title: '',
          attrs: {},
          tall: true,
          wide: true,
        },
      ],
    },
    commitments: {
      name: 'Commitments',
      slug: 'commitments',
      icon: TableCellsIcon,
      widgets: [
        {
          id: DashboardWidgetName.Commitments,
          name: DashboardWidgetName.Commitments,
          title: '',
          attrs: {},
          tall: true,
          wide: true,
        },
      ],
    },
  },
  mobile: {
    portfolio: {
      name: 'Portfolio',
      slug: 'portfolio',
      widgets: [
        {
          id: DashboardWidgetName.ValueHistoryPieChart,
          name: DashboardWidgetName.ValueHistoryPieChart,
          title: 'Value Pie Chart',
          attrs: {},
        },
        {
          id: DashboardWidgetName.ValueChangeSnapshot,
          name: DashboardWidgetName.ValueChangeSnapshot,
          title: 'Value Change Shapshot',
          attrs: {},
        },
        {
          id: DashboardWidgetName.LinkedTransactions,
          name: DashboardWidgetName.LinkedTransactions,
          title: 'Most recent linked transactions',
          attrs: getDefaultLinkedTransactionsAttrs(),
        },
      ],
    },
  },
}

export const DEFAULT_DESKTOP_DASHBOARDS: Record<
  UserPlanName,
  DashboardsItem[]
> = {
  [UserPlanName.STANDARD]: [DASHBOARD_VIEWS.desktop.portfolio],
  [UserPlanName.PROFESSIONAL]: [
    DASHBOARD_VIEWS.desktop.portfolio,
    DASHBOARD_VIEWS.desktop.linkedTransactions,
  ],
}

export const DEFAULT_MOBILE_DASHBOARDS: Record<UserPlanName, DashboardsItem[]> =
  {
    [UserPlanName.STANDARD]: [DASHBOARD_VIEWS.mobile.portfolio],
    [UserPlanName.PROFESSIONAL]: [DASHBOARD_VIEWS.mobile.portfolio],
  }
