<template>
  <div class="welcome-screen-analytics">
    <div v-if="isNoRepositoryError" class="welcome-screen-analytics__caption">
      Unknown repository with name <strong>"{{ repositoryName }}"</strong>
    </div>
    <div
      v-else-if="isNoAnalyticsError"
      class="welcome-screen-analytics__caption"
    >
      No transactions or error calculating analytics <br />for
      <strong>{{ repositoryEndDate }}</strong>
      <DashboardsTimeline
        :disabled="isLoading"
        hide-breadcrumb
        class="welcome-screen-analytics__timeline"
      />
    </div>
    <UILayoutNoData v-else-if="isReadonly" inline hide-question-icon>
      <template #text-description
        >No accounts connected with current repository</template
      >
      <template #text-question>What is a connectors?</template>
    </UILayoutNoData>
    <div
      v-else-if="isNoTransactionsError"
      class="welcome-screen-analytics__caption"
    >
      Get started by adding accounts and assets
    </div>
    <div v-else class="welcome-screen-analytics__error">
      <div class="welcome-screen-analytics__caption">Calculation error</div>
      <UIButton
        :leading="ArrowPathIcon"
        :loading="refreshing"
        :disabled="refreshing"
        @click="handleReload"
        >Refresh</UIButton
      >
    </div>
    <CreateActions v-if="isActionsVisible" />
  </div>
</template>

<script setup lang="ts">
import { computed, inject, ref } from 'vue'

import { AnalyticsError, ReadonlyMode } from '@types'
import { READONLY_MODE } from '@/const'

import useAnalyticsStore from '@/store/analytics'
import { useRepositoriesStore } from '@/store/repositories'

import { stringToLocalDateString } from '@/helpers/dates'

import CreateActions from '@/views/components/CreateActions.vue'
import DashboardsTimeline from '@/views/Dashboards/components/Timeline.vue'
import { ArrowPathIcon } from '@heroicons/vue/24/outline'
import { UIButton, UILayoutNoData } from '@ui'

const analyticsStore = useAnalyticsStore()
const repositoriesStore = useRepositoriesStore()

const isReadonly = inject<ReadonlyMode>(READONLY_MODE)

const refreshing = ref(false)

const repositoryName = computed(
  () => repositoriesStore.getCurrentRepositoryName,
)
const repositoryEndDate = computed(() =>
  stringToLocalDateString(repositoriesStore.getTimelineEnd),
)

const isLoading = computed(
  () => analyticsStore.isLoading || !analyticsStore.initFlag,
)

const errorMessage = computed(() => analyticsStore.getError as AnalyticsError)

const isNoRepositoryError = computed(
  () => errorMessage.value === AnalyticsError.NO_REPOSITORY,
)

const isNoAnalyticsError = computed(
  () => errorMessage.value === AnalyticsError.NO_ANALYTICS,
)

const isNoTransactionsError = computed(
  () => errorMessage.value === AnalyticsError.NO_TRANSACTIONS,
)

const isActionsVisible = computed(
  () => !isReadonly?.value && isNoTransactionsError.value,
)

const handleReload = () => {
  refreshing.value = true
  window.location.reload()
}
</script>

<style>
.welcome-screen-analytics {
  @apply flex flex-col items-center;
  @apply gap-4;

  &__caption {
    @apply text-sm;
    @apply text-center;
    @apply uppercase;
  }

  &__timeline {
    @apply !mt-6;
  }

  &__error {
    @apply flex flex-col items-center;
    @apply gap-4;
  }
}
</style>
