<template>
  <div class="ui-dotted-menu" :class="mainClasses" @click.stop.prevent>
    <UIButtonMenu
      class="ui-dotted-menu__toggler"
      :open="isOpen"
      @click="handleClick"
    />
    <div v-if="isOpen" class="ui-dotted-menu__area" :class="areaClasses">
      <slot>
        <UIButton
          v-for="item in items"
          :key="item.label"
          v-tooltip.top="item.tooltip"
          :size
          v-bind="omit(item, 'tooltip', 'action')"
          @click="
            item.action?.({
              callback: () => {
                isOpen = false
              },
              ...meta,
            })
          "
        />
      </slot>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue'
import { omit } from 'lodash'

import { ButtonSize } from '@types'
import { DottedMenuItem } from './utils/types'

import { UIButton, UIButtonMenu } from '@ui'

type Props = {
  items?: DottedMenuItem[]
  size?: ButtonSize
  direction?: 'left' | 'right'
  meta?: any
}

const props = defineProps<Props>()

defineOptions({
  name: 'UIDottedMenu',
})

defineExpose({
  show() {
    isOpen.value = true
  },
  hide() {
    isOpen.value = false
  },
})

const isOpen = ref(false)

const mainClasses = computed(() => ({
  'ui-dotted-menu--opened': isOpen.value,
  [`ui-dotted-menu--${props.direction || 'left'}`]: true,
}))

const areaClasses = computed(() => ({
  [`ui-dotted-menu__area--${props.direction || 'left'}`]: true,
}))

const handleClick = () => {
  isOpen.value = !isOpen.value
}
</script>

<style scoped>
.ui-dotted-menu {
  @apply h-full;
  @apply relative;
  @apply flex items-center;
  @apply gap-2;
  @apply bg-transparent;

  &--left {
    .ui-button-icon {
      @apply pr-0;
    }
  }

  &--right {
    .ui-button-icon {
      @apply pl-0;
    }
  }

  &__area {
    @apply absolute inset-y-0;
    @apply flex items-center;
    @apply gap-1 px-1;
    @apply bg-current;

    @apply after:content-[''];
    @apply after:w-10;
    @apply after:absolute after:inset-y-0;
    @apply after:from-current;

    &--left {
      @apply right-full;
      @apply after:right-full;
      @apply after:bg-gradient-to-l;
    }

    &--right {
      @apply left-full;
      @apply after:left-full;
      @apply after:bg-gradient-to-r;
    }

    :deep() .ui-button {
      @apply whitespace-nowrap;
    }
  }
}
</style>
