<template>
  <MainDesktopTabsItem
    v-if="item"
    ref="mainRef"
    :item
    class="main-desktop-tabs-dropdown-item"
    :class="itemClasses"
  >
    <Bars3Icon
      aria-hidden="true"
      class="main-desktop-tabs-dropdown-item__bars"
    />
    <InputString v-if="isEditMode" v-model="labelValue" @save="handleSave" />
    <MainDesktopTabsDropdownItemLabel v-else :item />
    <ViewTabDropdown v-bind="{ item }" menu-mode @edit="isEditMode = true" />
  </MainDesktopTabsItem>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'

import { NavigationItem } from '@types'

import InputString from './components/InputString.vue'
import MainDesktopTabsItem from './MainDesktopTabsItem.vue'
import MainDesktopTabsDropdownItemLabel from './MainDesktopTabsDropdownItemLabel.vue'
import ViewTabDropdown from './components/ViewTabDropdown.vue'
import { Bars3Icon } from '@heroicons/vue/24/outline'

type Props = {
  item?: NavigationItem
}

const props = defineProps<Props>()

const isEditMode = ref(false)
const labelValue = ref(props.item?.label || '')

const itemClasses = computed(() => ({
  'main-desktop-tabs-dropdown-item--active': props.item?.active,
}))

const handleSave = () => {
  props.item?.update?.(labelValue.value)
  isEditMode.value = false
}
</script>

<style>
.main-desktop-tabs-dropdown-item {
  .main-desktop-tabs-item {
    @apply flex items-center;
    @apply gap-2 pl-2.5 pr-5 py-2;
    @apply border-l-2 border-transparent;
    @apply text-gray-700 dark:text-gray-300;
    @apply text-xs;
  }

  .ui-dotted-menu {
    @apply -my-1.5 -mr-4;
  }

  .ui-dotted-menu__area {
    @apply text-body;
  }

  &:hover,
  &:has(.v-popper--shown) {
    @apply bg-gray-100 dark:bg-gray-750;
    @apply text-gray-900 dark:text-gray-100;

    .ui-dotted-menu__area {
      @apply text-gray-100 dark:text-gray-750;
    }
  }

  &--active {
    .main-desktop-tabs-item {
      @apply bg-gray-50 dark:bg-gray-850;
      @apply !text-primary dark:!text-indigo-300;
      @apply border-primary dark:border-indigo-300;
    }

    .ui-dotted-menu__area {
      @apply !text-gray-50 dark:!text-gray-850;
    }
  }

  &__bars {
    @apply w-4 h-4;
    @apply cursor-move;
  }

  &__more {
    @apply -my-0.5;
  }

  .main-input-string {
    @apply flex-auto;
    @apply !text-xs;
  }
}
</style>
