<template>
  <div
    class="ui-grid__header-sort"
    :class="mainClasses"
    @click="handleClick"
    @mouseup.stop
    @mousedown.stop
  >
    <component
      :is="topComponent"
      class="ui-grid__header-sort-arrow"
      aria-hidden="true"
    />
    <component
      :is="bottomComponent"
      class="ui-grid__header-sort-arrow"
      aria-hidden="true"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, inject } from 'vue'

import { Sort, SortDirection } from '@types'

import { GridColumn } from '../utils/types'

import {
  BarsArrowDownIcon,
  BarsArrowUpIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from '@heroicons/vue/20/solid'

type Props = {
  column?: GridColumn
}

const props = defineProps<Props>()

defineOptions({
  name: 'GridHeaderSort',
})

const modelValue = defineModel<Sort[]>()

const unitarySort = inject<boolean>('unitarySort', false)

const defaultDirection = computed(
  () => props.column?.defaultSortDirection || SortDirection.DESC,
)

const columnName = computed(
  () => props.column?.filter?.name || props.column?.name || '',
)

const currentValue = computed(() => {
  return modelValue.value?.find(item => item.field === columnName.value)
})

const currentDirection = computed(() => currentValue.value?.sortDirection)

const topComponent = computed(() => {
  if (currentValue.value) {
    return currentDirection.value === SortDirection.ASC
      ? BarsArrowDownIcon
      : BarsArrowUpIcon
  } else {
    return ChevronUpIcon
  }
})

const bottomComponent = computed(() =>
  !currentValue.value ? ChevronDownIcon : undefined,
)

const mainClasses = computed(() => ({
  'ui-grid__header-sort--active': !!currentValue.value,
}))

const handleClick = () => {
  if (currentValue.value) {
    let sortDirection: SortDirection
    if (currentDirection.value !== defaultDirection.value) {
      modelValue.value = modelValue.value?.filter(
        item => item.field !== columnName.value,
      )
      return
    } else if (currentDirection.value === SortDirection.DESC) {
      sortDirection = SortDirection.ASC
    } else if (currentDirection.value === SortDirection.ASC) {
      sortDirection = SortDirection.DESC
    }
    modelValue.value = modelValue.value?.map(item =>
      item.field === columnName.value ? { ...item, sortDirection } : item,
    )
  } else {
    if (unitarySort) {
      modelValue.value = [
        {
          field: columnName.value,
          sortDirection: defaultDirection.value,
        },
      ]
    } else {
      modelValue.value = [
        ...(modelValue.value || []),
        {
          field: columnName.value,
          sortDirection: defaultDirection.value,
        },
      ]
    }
  }
}
</script>

<style scoped lang="postcss">
.ui-grid__header-sort {
  @apply relative;
  @apply -mr-1 ml-0.5;
  @apply flex flex-col items-center;
  @apply text-gray-300 hover:text-gray-400;
  @apply dark:text-gray-400 dark:hover:text-gray-300;
  @apply cursor-pointer;

  &--active {
    @apply text-indigo-400 hover:text-indigo-500;
    @apply dark:text-indigo-300 dark:hover:text-indigo-400;
  }

  &-arrow {
    @apply h-4 w-4 -my-1;
  }
}
</style>
