import { HotKeysCallback } from './types'

export const prepareCallbacks = (callbacks?: HotKeysCallback[]) => {
  return callbacks
    ?.filter(cb => !cb.flag || cb.flag.value)
    ?.sort((a, b) => b.priority - a.priority)
}

export const generateUniqueId = () => {
  return (Math.random() + 1).toString(36).substring(7)
}
