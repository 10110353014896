import { CapacitorConfig } from '@capacitor/cli'
import { KeyboardResize } from '@capacitor/keyboard'

const config: CapacitorConfig = {
  appId: 'com.allposit.app',
  appName: 'Allposit',
  webDir: './dist',
  loggingBehavior: 'debug',
  backgroundColor: '4438ca',
  server: {
    hostname: 'app.allposit.com',
  },
  ios: {
    contentInset: 'always',
  },
  plugins: {
    Keyboard: {
      resize: KeyboardResize.None,
      resizeOnFullScreen: true,
    },
  },
}

export default config
