<template>
  <UIButton
    v-if="!isReadonly"
    v-bind="{ size }"
    :leading="PlusIcon"
    label="New"
    hide-label-to-sm
  />
</template>

<script setup lang="ts">
import { inject } from 'vue'

import { ButtonSize, ReadonlyMode } from '@types'

import { READONLY_MODE } from '@/const/common'

import { PlusIcon } from '@heroicons/vue/24/outline'
import { UIButton } from '@ui'

const size = inject<ButtonSize>('size')
const isReadonly = inject<ReadonlyMode>(READONLY_MODE)
</script>
