<template>
  <UITree
    v-if="data"
    v-bind="{ data, formatter, getDescription }"
    :meta-fields="['amount', 'balance', 'path']"
    :root="ANALYTICS_REPO_ROOT"
    sort-field="balance"
    size="xxsmall"
    placeholder="Search assets..."
    wide
    class="app-tree"
    @click:name="handleClickName"
    @on:contextmenu="handleClickMenu"
  >
    <template #root>{{ repoName }}</template>
    <template #meta="{ amount, balance, isRoot }">
      <div class="app-tree__values">
        <div class="app-tree__balance blurable-number">
          {{ balanceFormatter(balance) }}
        </div>
        <div v-if="!isRoot" class="app-tree__amount blurable-number">
          {{ amountFormatter(amount) }}
        </div>
      </div>
    </template>
    <template #before-item="{ keyValue, isLeafNode }">
      <UICheckbox
        v-if="isCheckboxVisible(keyValue)"
        v-model="selected"
        :value="{ keyValue, isLeafNode }"
        class="app-tree__checkbox"
        :class="getCheckboxClasses(keyValue)"
      />
      <div v-else class="w-4 shrink-0" />
    </template>
    <template #actions="{ item, isLeaf, keyValue }">
      <TreeMenu
        :item
        :key-value
        :is-leaf
        :current-node
        :editable="isEditableItem(keyValue)"
        :selected="isSelectedItem(keyValue)"
        @select="handleToggleSelect"
        @edit="handleClickEdit"
        @set-price="handleClickSetPrice"
        @filter="handleClickFilter"
      />
      <UIButtonIcon
        v-if="getConnectorInfo(keyValue)"
        v-tooltip.permanent="getConnectorInfo(keyValue)?.message"
        :leading="LinkIcon"
        fill="light"
        size="xs"
        class="app-tree__action-connector"
        :class="getConnectorClass(keyValue)"
        @click="handleClickConnector(item, isLeaf)"
      />
    </template>
    <template v-if="isPanelVisible" #after>
      <div class="app-tree__panel">
        <UIButton
          label="Group accounts"
          size="xs"
          @click="handleClickGroupAccounts"
        />
        <UIButton
          label="Cancel"
          variant="gray"
          fill="outlined"
          size="xs"
          @click="handleClickClosePanel"
        />
      </div>
    </template>
  </UITree>
  <div v-else class="app-tree__no-data">
    <UILayoutNoData inline hide-question-icon>
      <template #text-description>Empty tree</template>
    </UILayoutNoData>
  </div>
</template>

<script setup lang="ts">
import { computed, ComputedRef, inject, markRaw, ref } from 'vue'

import {
  AnalyticsPreparedTree,
  TreeNodeFormatter,
  TreeNodeDescriptionGetter,
  LinkedDataConnectorStatus,
  ReadonlyMode,
} from '@types'

import { ANALYTICS_REPO_ROOT, READONLY_MODE } from '@/const'
import { CONNECTOR_SYNC_STATUS_TEXT } from '@/views/LinkedData/utils/const'

import { numberFormat } from '@/helpers/numbers'

import { useModalsStore } from '@/store/modals'

import TreeMenu from './TreeMenu.vue'
import { LinkIcon, XMarkIcon } from '@heroicons/vue/24/outline'
import { UIButton, UIButtonIcon, UICheckbox, UILayoutNoData, UITree } from '@ui'
import AssetsGrouping from '@/modules/modals/AssetsGrouping.vue'
import AssetsSetPrice from '@/modules/modals/AssetsSetPrice.vue'

type Props = {
  data?: AnalyticsPreparedTree
  repoName?: string
  formatter: TreeNodeFormatter
  currentPath?: string[]
  getConnectorInfo: (key: string) =>
    | {
        status: LinkedDataConnectorStatus
        message: string
      }
    | undefined
  getDescription: TreeNodeDescriptionGetter
}

type Emits = {
  'click:menu': [item: AnalyticsPreparedTree, isLeaf: boolean]
  'click:name': [item: AnalyticsPreparedTree, isLeaf: boolean]
  'click:connector': [item: AnalyticsPreparedTree, isLeaf: boolean]
  'click:path': [item: AnalyticsPreparedTree, isLeaf: boolean]
}

const props = defineProps<Props>()
const emit = defineEmits<Emits>()

defineOptions({
  name: 'AppTree',
})

const modalsStore = useModalsStore()

const currency = inject<ComputedRef<string>>('currency')
const isReadonly = inject<ReadonlyMode>(READONLY_MODE)

const selected = ref<{ keyValue: string; isLeafNode: boolean }[]>([])

const selectedIds = computed(() => selected.value.map(item => item.keyValue))

const isPanelVisible = computed(
  () => !!selectedIds.value?.length && !isReadonly?.value,
)

const currentNode = computed(() => props.currentPath?.at(-1))

const balanceFormatter = (value: number) =>
  numberFormat(value, { currency: currency?.value })

const amountFormatter = (value: number) => numberFormat(value)

const getCheckboxClasses = (keyValue: string) => {
  return isSelectedItem(keyValue) ? 'app-tree__checkbox--checked' : ''
}

const isSelectedItem = (id: string) => {
  return !!selected.value.find(item => item.keyValue === id)
}

const isEditableItem = (id: string) => {
  return id !== ANALYTICS_REPO_ROOT && !isReadonly?.value
}

const isCheckboxVisible = (id: string) => {
  return isEditableItem(id) && isSelectedItem(id)
}

const handleToggleSelect = (keyValue: string, isLeafNode: boolean) => {
  if (isSelectedItem(keyValue)) {
    selected.value = selected.value.filter(item => item.keyValue !== keyValue)
  } else {
    selected.value.push({ keyValue, isLeafNode })
  }
}

const getConnectorClass = (key: string) => {
  const status = props.getConnectorInfo(key)?.status
  if (!status) return
  const statusValue = CONNECTOR_SYNC_STATUS_TEXT[status]?.toLowerCase()
  return `app-tree__action-connector--${statusValue}`
}

const handleClickConnector = (item: AnalyticsPreparedTree, isLeaf: boolean) => {
  emit('click:connector', item, isLeaf)
}

const handleClickMenu = (item: AnalyticsPreparedTree, isLeaf: boolean) => {
  emit('click:menu', item, isLeaf)
}

const handleClickName = (item: AnalyticsPreparedTree, isLeaf: boolean) => {
  emit('click:name', item, isLeaf)
}

const handleClickFilter = (item: AnalyticsPreparedTree, isLeaf: boolean) => {
  emit('click:path', item, isLeaf)
}

const handleClickEdit = (item: AnalyticsPreparedTree, isLeaf: boolean) => {
  emit('click:name', item, isLeaf)
}

const handleClickClosePanel = () => {
  selected.value = []
}

const handleClickGroupAccounts = () => {
  const modalInstance = modalsStore.init(
    'assets-grouping',
    markRaw(AssetsGrouping),
  )
  modalInstance?.open(modalsStore.getZIndex(), {
    ids: selectedIds.value,
    onComplete: () => {
      selected.value = []
    },
  })
}

const handleClickSetPrice = (id: string) => {
  const modalInstance = modalsStore.init(
    'assets-set-price',
    markRaw(AssetsSetPrice),
  )
  modalInstance?.open(modalsStore.getZIndex(), {
    ids: [id],
    onComplete: () => {
      selected.value = []
    },
  })
}
</script>

<style>
.app-tree {
  @apply pl-1.5 pr-2.5;

  .ui-tree__search {
    @apply gap-x-1;
    @apply pt-2.5 px-0.5;

    .field__leading {
      @apply !px-1.5;
    }

    .field__input {
      @apply !pl-6;
    }

    .field__clear {
      @apply right-1.5;
    }
  }

  .ui-tree__container {
    @apply flex flex-col;
    @apply gap-1 px-0.5;
  }

  .ui-tree__item {
    @apply justify-between;
    @apply pl-0.5;
    @apply bg-body-dark hover:bg-body-gray;

    .ui-slide-panel & {
      @apply bg-body hover:bg-body-dark dark:bg-body-dark dark:hover:bg-body-gray;
    }

    &:has(.app-tree__checkbox--checked) {
      @apply !bg-indigo-50 dark:!bg-gray-900;
      @apply outline outline-1 outline-indigo-100 dark:outline-gray-800;
    }
  }

  .ui-tree__item-area {
    @apply items-start justify-between;
    @apply pt-[0.45rem] pl-2 gap-x-2;

    &--root {
      @apply pb-2;
      @apply items-center;
    }
  }

  .ui-tree__name {
    @apply shrink;

    @apply px-1.5 -mx-1.5;
    @apply hover:bg-indigo-50 dark:hover:bg-gray-700;
    @apply hover:text-indigo-600 dark:hover:text-indigo-300;
    @apply rounded;
    @apply cursor-pointer;
  }

  .ui-tree__value {
    @apply shrink-0;
    @apply p-1 -m-1;
  }

  .ui-tree__actions {
    @apply -mr-1 md:-mr-3.5;
  }

  .ui-tree__branch--l {
    @apply before:-top-[calc(0.125rem+1px)];
  }

  .ui-tree__branch--i,
  .ui-tree__branch--x {
    @apply before:-top-[calc(0.125rem+1px)];
  }

  &__checkbox {
    @apply m-0 mt-2;

    .ui-checkbox__control {
      @apply border-indigo-500;
    }
  }

  &__values {
    @apply flex-auto;
    @apply text-xs;
    @apply text-right;
  }

  &__balance {
    @apply text-gray-700 dark:text-gray-300;
  }

  &__amount {
    @apply text-gray-400 dark:text-gray-500;
    @apply font-light;
  }

  &__no-data {
    @apply flex flex-col flex-auto justify-center;
  }

  &__action {
    @apply !px-0;
    @apply z-10;

    &--shifted {
      @apply mr-5;
    }
  }

  &__action-connector {
    @apply px-1.5 -mx-1.5;

    .ui-button {
      @apply w-5;
      @apply !aspect-auto;
    }

    &--error {
      .ui-button {
        @apply !text-red-800 dark:!text-red-300;
      }
    }

    &--warning {
      .ui-button {
        @apply !text-yellow-800 dark:!text-yellow-300;
      }
    }
  }

  &__panel {
    @apply sticky bottom-0;
    @apply flex items-center justify-between;
    @apply gap-2 py-2 px-0.5;
    @apply bg-body-dark;

    .ui-slide-panel & {
      @apply mt-auto;
      @apply bg-body dark:bg-body-dark;
    }
  }
}
</style>
