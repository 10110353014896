<template>
  <SelectRepository>
    <template #default="{ postfixIcon, repositoryName }">
      <div
        v-if="isNavbarOpened"
        class="navbar-node navbar-item__repos"
        :class="{ 'navbar-node--active': isRepositoryOpened }"
        @click="handleToggle"
      >
        <div class="navbar-node__icon">
          <RectangleStackIcon aria-hidden="true" />
        </div>
        <div class="navbar-node__label">
          {{ t('Repositories') }}
          <component
            :is="postfixIcon"
            class="navbar-item__status"
            aria-hidden="true"
          />
        </div>
        <UIButtonIcon
          :trailing="
            isRepositoryOpened ? ChevronDoubleLeftIcon : ChevronDoubleRightIcon
          "
          variant="gray"
          size="xxs"
          fill="light"
        />
        <template v-if="isRepositoryOpened">
          <div class="navbar-item__arrow navbar-item__arrow--top" />
          <div class="navbar-item__arrow navbar-item__arrow--bottom" />
        </template>
      </div>
      <UIButtonIcon
        v-else
        v-tooltip.right="isRepositoryOpened ? undefined : repositoryName"
        data-refid="navbarRepository"
        :leading="RectangleStackIcon"
        :variant="isRepositoryOpened ? 'secondary' : 'gray'"
        :fill="isRepositoryOpened ? undefined : 'outlined'"
        size="sm"
        class="navbar-item-menu navbar-item__repos"
        :class="{ 'navbar-item--active': isRepositoryOpened }"
        @click="handleToggle"
      >
        {{ t('Repos') }}
        <template v-if="postfixIcon && !isNavbarOpened">
          <component
            :is="postfixIcon"
            class="navbar-item__status navbar-item__status--float"
            aria-hidden="true"
          />
        </template>
        <template v-if="isRepositoryOpened">
          <div class="navbar-item__arrow navbar-item__arrow--top" />
          <div class="navbar-item__arrow navbar-item__arrow--bottom" />
        </template>
      </UIButtonIcon>
    </template>
  </SelectRepository>
</template>

<script setup lang="ts">
import { inject } from 'vue'

import { OpenedFlag } from './utils/type'

import { NAVBAR_OPENED, REPOSITORIES_OPENED, TREE_OPENED } from './utils/const'

import { useLocale } from '@/plugins/i18n'

import SelectRepository from './components/SelectRepository.vue'
import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  RectangleStackIcon,
} from '@heroicons/vue/24/outline'
import { UIButtonIcon } from '@ui'

const { t } = useLocale('layouts.Main')

const isNavbarOpened = inject<OpenedFlag>(NAVBAR_OPENED)
const isRepositoryOpened = inject<OpenedFlag>(REPOSITORIES_OPENED)
const isTreeOpened = inject<OpenedFlag>(TREE_OPENED)

const handleToggle = () => {
  isTreeOpened && (isTreeOpened.value = false)
  isRepositoryOpened && (isRepositoryOpened.value = !isRepositoryOpened.value)
}
</script>

<style>
.navbar-item__repos {
  @apply mt-auto;
}
</style>
