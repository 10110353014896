<template>
  <component
    :is="disabled ? 'div' : 'RouterLink'"
    :to="disabled ? undefined : { name: item.route, params: item.routeParams }"
    :data-refid="dataRefId"
    class="navbar-node-child"
    :class="mainClasses"
  >
    <div class="navbar-node-child__icon">
      <component :is="displayIcon" aria-hidden="true" />
    </div>
    <InputString v-if="isEditMode" v-model="labelValue" @save="handleSave" />
    <div v-else class="navbar-node-child__label">{{ item.label }}</div>
    <ViewTabDropdown :item menu-mode @edit="isEditMode = true" />
  </component>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { upperFirst } from 'lodash'
import { useRoute } from 'vue-router'

import { NavigationItem } from '@types'

import ViewTabDropdown from './ViewTabDropdown.vue'
import { Bars3Icon } from '@heroicons/vue/24/outline'
import InputString from './InputString.vue'

type Props = {
  item: NavigationItem
  disabled?: boolean
}

const props = defineProps<Props>()

const route = useRoute()

const isEditMode = ref(false)
const labelValue = ref(props.item?.label || '')

const dataRefId = computed(
  () =>
    `panelMenuNodeChild${props.item.label
      ?.split(' ')
      .map(item => upperFirst(item))
      .join('')}`,
)

const displayIcon = computed(() => props.item.icon || Bars3Icon)

const mainClasses = computed(() => {
  return {
    'navbar-node-child--disabled': props.disabled,
    'router-link-exact-active':
      props.disabled &&
      route.name === props.item.route &&
      JSON.stringify(route.params) === JSON.stringify(props.item.routeParams),
  }
})

const handleSave = () => {
  props.item?.update?.(labelValue.value)
  isEditMode.value = false
}
</script>

<style>
.navbar-node-child {
  @apply min-h-8;
  @apply flex items-center;
  @apply gap-2 p-1 pl-6;
  @apply hover:bg-body-dark;
  @apply text-gray-700 dark:text-gray-400;
  @apply hover:text-common-text;
  @apply rounded;

  &.router-link-exact-active {
    @apply !bg-indigo-50 dark:!bg-gray-900;
    @apply outline outline-1 outline-indigo-100 dark:outline-gray-800;
    @apply text-primary dark:text-indigo-400;
  }

  &--disabled {
    @apply opacity-50;
    @apply cursor-default;
  }

  &__icon {
    @apply cursor-move;

    svg {
      @apply size-4;
    }
  }

  &__label {
    @apply flex-auto;
    @apply text-sm;
    @apply truncate;
  }

  .main-input-string {
    @apply flex-auto;
  }

  .ui-dotted-menu {
    @apply hidden;
    @apply -my-1.5 -mr-0.5;
  }

  &:hover {
    .ui-dotted-menu {
      @apply block;
    }
  }

  .ui-dotted-menu__area {
    @apply text-body-dark;
  }

  &.router-link-exact-active {
    .ui-dotted-menu__area {
      @apply text-indigo-50 dark:text-gray-900;
    }
  }
}
</style>
