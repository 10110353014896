import { computed, inject, nextTick, reactive, ref, watch } from 'vue'
import { defineStore } from 'pinia'
import { useDebounceFn } from '@vueuse/core'
import { isEqual, reduce } from 'lodash'

import { AnalyticsError } from './utils/enums'
import { WSInstance, WSResponse } from '@/services/ws/utils/types'
import { AnalyticsRequestsMap } from './utils/types'

import {
  ANALYTICS_REPO_ROOT,
  ANALYTICS_DELAY_TO_NOTIFICATE,
  ANALYTICS_TIMEOUT_DELAY,
  ANALYTICS_TIMEOUT_MESSAGE,
  ANALYTICS_DATE_STORAGE_KEY,
} from './utils/const'
import { DEBOUNCE_DELAY, NOTIFICATION_DELAY } from '@/const/common'

import {
  compareRequestWithResponse,
  convertToSeconds,
  prepareDatesRangeSlice,
} from './utils/helpers'
import { handleCatchedError } from '@/helpers/common'
import { generateUniqueRequestId } from '@/services/ws/utils/helpers'

import { useRepositoriesStore } from '../repositories'
import { useRepoLocalStorage } from '@/hooks/repoLocalStorage'
import { useNotifications } from '@/plugins/notification'
import { useUserStore } from '../user'

import api from '@/store/api'
import faro from '@/services/faro'

import useCommitmentsStore from './commitments'
import useIncomeStore from './income'
import useIncomeHistoryStore from './income_history'
import useIrrMoicStore from './irr_moic'
import usePerformanceStore from './performance'
import usePositionStore from './position'
import useTreeStore from './tree'
import useValueStore from './value'
import useValueHistoryStore from './value_history'

export default defineStore('analytics', () => {
  // INIT
  const repositoriesStore = useRepositoriesStore()
  const userStore = useUserStore()

  const { error, warn } = useNotifications()

  const ws = inject<WSInstance>('ws')
  const isWSDisabled = inject<boolean>('wsDisabled', false)

  const analyticsTimer = ref<NodeJS.Timeout>()

  const progress = ref(0)

  const active = reactive<{
    [key in string]?: AnalyticsStoreActiveKeys[]
  }>({})

  const module: AnalyticsStore = {
    commitments: useCommitmentsStore(),
    income: useIncomeStore(),
    income_history: useIncomeHistoryStore(),
    irr_moic: useIrrMoicStore(),
    performance: usePerformanceStore(),
    position: usePositionStore(),
    tree: useTreeStore(),
    value: useValueStore(),
    value_history: useValueHistoryStore(),
  }

  let abortController = new AbortController()

  const calculationError = ref<string>()
  const path = ref<string[]>([])

  const initFlag = ref(false)

  const trigger = ref(0)

  const requestsMap = ref<AnalyticsRequestsMap>(new Map())

  const repositoryId = computed(() => repositoriesStore.currentRepositoryId)
  const repositoryTimeline = computed(() => {
    return repositoriesStore.getCurrentTimeline
  })
  const repositoryTimelineEnd = computed(() => repositoriesStore.getTimelineEnd)
  const repositoryTimelinePeriods = computed(
    () => repositoryTimeline.value?.periods,
  )

  const storageDate = useRepoLocalStorage<string | undefined>(
    ANALYTICS_DATE_STORAGE_KEY,
    undefined,
  )

  const isLoading = computed(() => {
    return (
      (isActive('income') && module.income.isLoading) ||
      (isActive('income_history') && module.income_history.isLoading) ||
      (isActive('irr_moic') && module.irr_moic.isLoading) ||
      (isActive('performance') && module.performance.isLoading) ||
      (isActive('position') && module.position.isLoading) ||
      (isActive('tree') && module.tree.isLoading) ||
      (isActive('value') && module.value.isLoading) ||
      (isActive('value_history') && module.value_history.isLoading)
    )
  })

  // GETTERS

  const getAnalyticsParams = computed(() => ({
    ...repositoryTimeline.value,
    end: repositoryTimelineEnd.value,
    include_end: true,
    currency: repositoriesStore.getCurrentRepositoryCurrency,
  }))

  const getActiveKeys = computed(() => {
    const keys = reduce(
      Object.values(active),
      (acc: string[], value) => {
        if (!value) return acc
        return [...acc, ...value]
      },
      [],
    )
    return reduce(
      keys,
      (acc, item) => {
        const key = item as keyof AnalyticsStore
        if (acc.includes(key)) return acc
        acc.push(key)
        return acc
      },
      [] as (keyof AnalyticsStore)[],
    )
  })

  const isActive = (key: keyof AnalyticsStore) =>
    getActiveKeys.value.includes(key)

  const getDate = computed(() => {
    if (!storageDate.value) return
    return storageDate.value
  })

  const getPath = computed(() => {
    return path.value?.length ? path.value : [ANALYTICS_REPO_ROOT]
  })

  const getDatesRangeWithZeroDate = computed(() => {
    if (getActiveKeys.value.includes('performance')) {
      return (
        module.performance.getDatesRange || module.irr_moic.getDatesRange || []
      )
    }
    if (getActiveKeys.value.includes('income')) {
      return module.income.getDatesRange || []
    }
    if (getActiveKeys.value.includes('value')) {
      return module.value.getDatesRange || []
    }
    return (
      module.value_history.getDatesRange ||
      module.income_history.getDatesRange ||
      []
    )
  })

  const getDatesRange = computed(() => {
    return prepareDatesRangeSlice(
      getDatesRangeWithZeroDate.value,
      repositoryTimelinePeriods.value,
    )
  })

  const pathRootId = computed(() => {
    return getPath.value.at(-1)
  })

  const getError = computed(() => calculationError.value)

  // SETTERS

  const setDate = (data?: string) => {
    storageDate.value = data
  }

  const setActive = (key: string, data: AnalyticsStoreActiveKeys[]) => {
    active[key] = data
  }

  const setInActive = (key: string) => {
    delete active[key]
  }

  const setPath = (data: string[]) => {
    path.value = data
    module.value_history.setGroupValue('')
    module.value_history.setSelectedAsset('')
    markAsDeprecated(['tree', 'irr_moic'])
  }

  const markAsDeprecated = (
    exclude = [] as (keyof AnalyticsStore)[],
    flag = true,
  ) => {
    calculationError.value = undefined
    Object.keys(module).forEach(item => {
      const key = item as keyof AnalyticsStore
      if (exclude?.includes(key)) return
      module[key].isDeprecated = flag
    })
    trigger.value++
  }

  // ACTIONS

  const checkIfViewNeedToUpdate = (
    acc: string[],
    key: keyof AnalyticsStore,
  ) => {
    if (module[key] && (module[key].isDeprecated || !module[key].isInit)) {
      acc.push(key)
    }
    return acc
  }

  const changeModulesLoadingFlag = (views: string[], flag: boolean) => {
    views.forEach(view => {
      const key = view as keyof AnalyticsStore
      module[key].isLoading = flag
    })
  }

  const showAnalyticsDuration = async (ms: number) => {
    const seconds = convertToSeconds(ms)
    if (seconds > ANALYTICS_DELAY_TO_NOTIFICATE) {
      await warn(
        {
          message: `Analytics calculated in ${seconds} sec`,
        },
        NOTIFICATION_DELAY,
      )
      faro?.measure(
        'Analytics delay',
        {
          analyticLoadingTime: seconds,
        },
        {
          repositoryId: repositoryId.value,
          ...getAnalyticsParams.value,
        },
      )
    }
  }

  const onAnalyticsTimeout = () => {
    error({
      message: ANALYTICS_TIMEOUT_MESSAGE,
    })
    calculationError.value = ANALYTICS_TIMEOUT_MESSAGE
    initFlag.value = true
    cancelWSRequests()
    clearModules()
  }

  const sendAnalyticsRequest = (
    data: string,
    additionalParams: Record<string, any>,
  ) => {
    const rid = repositoryId.value
    if (!rid) return
    const params = {
      rid,
      params: {
        ...getAnalyticsParams.value,
        ...additionalParams,
        data,
      },
    }
    const uniqueId = generateUniqueRequestId()
    requestsMap.value.set(uniqueId, {
      rid,
      data,
      timeline: getAnalyticsParams.value,
      params: additionalParams,
    })
    progress.value = 0
    ws?.send('analytics', 'get', uniqueId, params)
  }

  const fetch = async (inputParams: Record<string, any>) => {
    const params = {
      ...getAnalyticsParams.value,
      ...inputParams,
    }
    const result = await api.get(
      `repositories/${repositoryId.value}/analytics`,
      {
        params,
        signal: abortController.signal,
      },
    )
    return result.data
  }

  const updateModuleData = (key: keyof AnalyticsStore, data: any) => {
    module[key].setList(data)
    module[key].isLoading = false
  }

  const refreshAnalytics = useDebounceFn(async () => {
    if (isWSDisabled) cancel()
    calculationError.value = undefined
    // checking analytics stores for deprecated
    const views = reduce(getActiveKeys.value, checkIfViewNeedToUpdate, [])
    if (!views.length) return
    const startTime = new Date().getTime()
    try {
      changeModulesLoadingFlag(views, true)
      const promises: Promise<any>[] = []
      if (views.includes('position')) {
        const accounts = JSON.stringify([pathRootId.value])
        if (isWSDisabled) {
          promises.push(await fetch({ accounts, data: 'position' }))
        } else {
          sendAnalyticsRequest('position', {
            accounts,
          })
        }
      }
      const remaindedViews = views.filter(view => view !== 'position')
      if (remaindedViews.length) {
        const root = pathRootId.value
        const data = remaindedViews.join(',')
        if (isWSDisabled) {
          promises.push(await fetch({ root, data }))
        } else {
          sendAnalyticsRequest(data, {
            root,
          })
        }
      }
      if (isWSDisabled) {
        const result = await Promise.all(promises)
        const concatResult = Object.assign({}, ...result)
        views.forEach(view => {
          const key = view as keyof AnalyticsStore
          updateModuleData(key, concatResult)
        })
        changeModulesLoadingFlag(views, false)
        const waitingTime = new Date().getTime() - startTime
        if (waitingTime) {
          showAnalyticsDuration(waitingTime)
        }
      }
    } catch (e) {
      if (isWSDisabled) {
        const { message } = e as Error
        if (message === 'canceled') return
        handleError(views, message)
      }
    } finally {
      if (isWSDisabled) {
        initFlag.value = true
      }
    }
  }, DEBOUNCE_DELAY)

  const refresh = (exclude?: (keyof AnalyticsStore)[]) => {
    getActiveKeys.value.forEach(key => {
      if (exclude?.includes(key)) return
      module[key].isLoading = true
    })
    nextTick(() => {
      getActiveKeys.value.forEach(key => {
        if (exclude?.includes(key)) return
        module[key].setList({} as any)
      })
    })
  }

  const cancel = () => {
    abortController.abort()
    abortController = new AbortController()
  }

  const cancelWSRequests = () => {
    requestsMap.value = new Map()
  }

  const clearModules = () => {
    Object.values(module).forEach((item: any) => {
      item.clear()
    })
  }

  const clear = (whole = true) => {
    if (whole) {
      calculationError.value = undefined
    }
    initFlag.value = false
    setPath([])
    setDate(undefined)
    cancelWSRequests()
    clearModules()
  }

  const handleError = async (views: string[], message: string | string[]) => {
    const errorMessage = Array.isArray(message) ? message.join(', ') : message
    calculationError.value = errorMessage
    changeModulesLoadingFlag(views, false)
    if (
      !(Object.values(AnalyticsError) as string[]).some(value =>
        Array.isArray(message) ? message.includes(value) : message === value,
      )
    ) {
      handleCatchedError(errorMessage, {
        repositoryId: repositoryId.value || '',
        ...getAnalyticsParams.value,
      })
      await error({
        message: `Analytics calculated with an error`,
      })
    }
  }

  const checkWSResult = (requestId: string) => {
    const request = requestsMap.value.get(requestId)
    return compareRequestWithResponse(
      request,
      repositoryId.value,
      getAnalyticsParams.value,
      pathRootId.value,
    )
  }

  watch(
    () => [getActiveKeys.value, repositoryId.value, trigger.value],
    (value, prev) => {
      if (!repositoryId.value || isEqual(value, prev) || getError.value) return
      refreshAnalytics()
    },
    { deep: true, immediate: true },
  )

  watch(getDatesRange, values => {
    if (!values.length || (getDate.value && values.includes(getDate.value)))
      return
    setDate(values.at(-1))
  })

  watch(
    () => userStore.isAuthenticated,
    value => {
      if (!value || isWSDisabled) return
      ws?.subscribe(
        'analytics',
        '_get',
        async (message: WSResponse<any>, requestId: string) => {
          analyticsTimer.value && clearTimeout(analyticsTimer.value)
          analyticsTimer.value = setTimeout(
            onAnalyticsTimeout,
            ANALYTICS_TIMEOUT_DELAY,
          )
          if (!checkWSResult(requestId)) return
          if (message.data.progress) {
            progress.value = message.data.progress
          }
        },
      )
      ws?.subscribe(
        'analytics',
        'get',
        async (message: WSResponse<any>, requestId: string) => {
          analyticsTimer.value && clearTimeout(analyticsTimer.value)
          initFlag.value = true
          if (!checkWSResult(requestId)) return
          progress.value = 100
          const { data, errors } = message
          if (errors) {
            const views = Object.keys(module)
            handleError(views, errors)
            return
          }
          const request = requestsMap.value.get(requestId)
          request?.data.split(',').forEach(view => {
            if (view in module) {
              const key = view as AnalyticsStoreActiveKeys
              updateModuleData(key, data)
            }
          })
          if (data.total_compute_time) {
            showAnalyticsDuration(data.total_compute_time)
          }
        },
      )
    },
    { immediate: true },
  )

  return {
    initFlag,
    isLoading,

    progress,

    module,
    isActive,

    fetch,

    getAnalyticsParams,

    getDate,
    getDatesRange,
    getDatesRangeWithZeroDate,
    getPath,
    getRoot: pathRootId,
    getError,

    setDate,
    setActive,
    setInActive,
    setPath,

    markAsDeprecated,

    refresh,
    cancel,
    clear,
  }
})

type AnalyticsCommitmentsStore = ReturnType<typeof useCommitmentsStore>
type AnalyticsIncomeStore = ReturnType<typeof useIncomeStore>
type AnalyticsIncomeHistoryStore = ReturnType<typeof useIncomeHistoryStore>
type AnalyticsIrrMoicStore = ReturnType<typeof useIrrMoicStore>
type AnalyticsPerformanceStore = ReturnType<typeof usePerformanceStore>
type AnalyticsPositionStore = ReturnType<typeof usePositionStore>
type AnalyticsTreeStore = ReturnType<typeof useTreeStore>
type AnalyticsValueStore = ReturnType<typeof useValueStore>
type AnalyticsValueHistoryStore = ReturnType<typeof useValueHistoryStore>

type AnalyticsStore = {
  commitments: AnalyticsCommitmentsStore
  income: AnalyticsIncomeStore
  income_history: AnalyticsIncomeHistoryStore
  irr_moic: AnalyticsIrrMoicStore
  performance: AnalyticsPerformanceStore
  position: AnalyticsPositionStore
  tree: AnalyticsTreeStore
  value: AnalyticsValueStore
  value_history: AnalyticsValueHistoryStore
}

type AnalyticsStoreActiveKeys = keyof AnalyticsStore
