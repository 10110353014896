<template>
  <MainDesktopTabsItem
    v-if="item"
    ref="mainRef"
    :item
    :disabled
    data-refid="mainMenuItem"
    class="main-desktop-tabs-primary-item"
    :class="itemClasses"
  >
    <MainDesktopTabsPrimaryItemLabel :item :disabled />
  </MainDesktopTabsItem>
</template>

<script setup lang="ts">
import { computed, useTemplateRef } from 'vue'
import { MaybeElement, useElementVisibility } from '@vueuse/core'

import { NavigationItem } from '@types'

import MainDesktopTabsPrimaryItemLabel from './MainDesktopTabsPrimaryItemLabel.vue'
import MainDesktopTabsItem from './MainDesktopTabsItem.vue'

type Props = {
  item?: NavigationItem
  disabled?: boolean
}

const props = defineProps<Props>()

const mainRef = useTemplateRef<MaybeElement>('mainRef')

const isVisible = useElementVisibility(mainRef, { threshold: 1 })

const itemClasses = computed(() => ({
  'main-desktop-tabs-primary-item--active': props.item?.active,
  'main-desktop-tabs-primary-item--disabled': props.disabled,
  'main-desktop-tabs-primary-item--hidden': !isVisible.value,
}))
</script>

<style>
.main-desktop-tabs-primary-item {
  @apply flex;
  @apply text-sm text-gray-600 dark:text-gray-300;
  @apply hover:text-primary dark:hover:text-indigo-300;
  @apply border-b-2 border-transparent;
  @apply whitespace-nowrap;

  &--disabled {
    @apply opacity-50;
  }

  &--active {
    @apply text-primary dark:text-indigo-300;
    @apply border-primary dark:border-indigo-300;
    @apply !opacity-90;
  }

  &--active:not(&--disabled) {
    @apply !opacity-100;
  }
}
</style>
