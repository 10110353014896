<template>
  <SelectRepository>
    <template
      #default="{
        items,
        isActionsAllowed,
        handleClickItem,
        handleExport,
        repositoryId,
        search,
        updateSearch,
        filter,
        filterFields,
        updateFilter,
      }"
    >
      <div class="navbar-repositories">
        <div class="navbar-repositories__header">
          <div class="navbar-repositories__title">Repositories</div>
          <SelectRepositoryActions
            :disabled="!isActionsAllowed"
            size="xs"
            @click="handleClose"
          />
        </div>
        <div class="navbar-repositories__search">
          <div class="navbar-repositories__search-input">
            <UIPanelSearch
              :model-value="search"
              placeholder="Search repositories..."
              size="xs"
              clearable
              @update:model-value="updateSearch"
            />
          </div>
          <UIFilter
            :model-value="filter"
            size="xs"
            label=""
            :fields="filterFields"
            flat
            @update:model-value="updateFilter"
          />
        </div>
        <UILayoutNoData
          v-if="!items.length"
          inline
          hide-question-icon
          class="navbar-repositories__no-data"
        />
        <div v-else class="navbar-repositories__items">
          <SelectRepositoryItem
            v-for="item in items"
            :key="item.id"
            :item
            :active="item.id === repositoryId"
            @delete="handleDelete"
            @click="handleClickItem(item)"
            @export="handleExport"
          />
        </div>
        <SelectRepositoryLimit v-if="!isActionsAllowed" />
        <SelectRepositoryDelete
          v-if="deleteRepository"
          :repository="deleteRepository"
          @close="handleCancelDelete"
          @export="handleExport"
        />
      </div>
    </template>
  </SelectRepository>
</template>

<script setup lang="ts">
import { ref } from 'vue'

import { Repository } from '@types'

import SelectRepository from './SelectRepository.vue'
import SelectRepositoryActions from './SelectRepositoryActions.vue'
import SelectRepositoryDelete from './SelectRepositoryDelete.vue'
import SelectRepositoryItem from './SelectRepositoryItem.vue'
import SelectRepositoryLimit from './SelectRepositoryLimit.vue'
import { UIFilter, UILayoutNoData, UIPanelSearch } from '@ui'

const deleteRepository = ref<Repository>()

const handleDelete = (item: Repository) => {
  deleteRepository.value = item
}

const handleCancelDelete = () => {
  deleteRepository.value = undefined
}

const handleClose = () => {}
</script>

<style>
.navbar-repositories {
  @apply flex flex-col flex-auto;

  &__header {
    @apply h-12;
    @apply flex items-center justify-between;
    @apply gap-4 pr-2.5 pl-7;
  }

  &__search {
    @apply flex items-center;
    @apply gap-1 py-[3px] p-2 mb-2;
    @apply bg-body-gray;
    @apply border-y border-common-border;

    .field__leading {
      @apply !px-1.5;
    }

    .field__input {
      @apply !pl-6;
    }

    .field__clear {
      @apply right-1.5;
    }

    .ui-button {
      @apply ring-offset-1;
    }
  }

  &__search-input {
    @apply flex-auto;

    .field__input {
      @apply !border-none;
      @apply !bg-transparent;
    }
  }

  &__title {
    @apply text-common-text;
    @apply text-gray-400 dark:text-gray-500;
    @apply text-xs font-medium;
    @apply tracking-wider;
    @apply uppercase;
  }

  &__items {
    @apply flex flex-col flex-auto;
    @apply gap-2 px-2;
  }

  &__no-data {
    @apply flex-auto;
  }
}
</style>
