import { computed, reactive, ref, watch } from 'vue'
import { defineStore } from 'pinia'
import { useRoute, useRouter } from 'vue-router'
import { isEqual } from 'lodash'

import { NavigationItem, NavigationPreset } from '@types'

import { ROUTE_NAME } from '@/const'
import {
  CHILDREN_NAVIGATION,
  DASHBOARD_NAME,
  PARENT_NAVIGATION,
} from './utils/const'

import { useAssetsBunchStore } from '@/store/assets/bunch'
import { useContactsBunchStore } from '@/store/contacts/bunch'
import { useDashboardsSettingsStore } from '@/store/settings/dashboards'
import { useDocumentsBunchStore } from '@/store/documents/bunch'
import { useTagsBunchStore } from '@/store/tags/bunch'
import { useTransactionsBunchStore } from '@/store/transactions/bunch'
import { useSettingsStore } from '../settings'

import { Cog6ToothIcon, TrashIcon } from '@heroicons/vue/24/outline'

export const useNavigationStore = defineStore('navigation', () => {
  // INIT

  const assetsBunchStore = useAssetsBunchStore()
  const contactsBunchStore = useContactsBunchStore()
  const dashboardSettingsStore = useDashboardsSettingsStore()
  const documentsBunchStore = useDocumentsBunchStore()
  const tagsBunchStore = useTagsBunchStore()
  const transactionsBunchStore = useTransactionsBunchStore()
  const settingsStore = useSettingsStore()

  const route = useRoute()
  const router = useRouter()

  const currentParentKey = ref()

  const headerKeys = reactive(
    Object.fromEntries(
      PARENT_NAVIGATION.map(item => [
        item.label,
        localStorage.getItem(`nav-${item.label}`),
      ]),
    ),
  )

  // GETTERS

  const filteredParentNavigation = computed(() =>
    PARENT_NAVIGATION.filter(
      item => item.label !== 'Analytics' || settingsStore.getAnalyticsDebugTabs,
    ),
  )

  const getParentNavigation = computed(() =>
    filteredParentNavigation.value.map(item => {
      const key = item.label as keyof typeof CHILDREN_NAVIGATION
      const children = CHILDREN_NAVIGATION[key]
      const cachedRoute = headerKeys[key]
      let route = item.route || children?.[0]?.route
      let routeParams
      if (cachedRoute) {
        let isJson = true
        let result
        try {
          result = JSON.parse(cachedRoute)
        } catch {
          isJson = false
        }
        if (key === DASHBOARD_NAME && isJson) {
          route = result?.name
          routeParams = result?.params
        } else {
          const hasTheChild = children?.find(
            child => child.route === cachedRoute,
          )
          const isRouteExists = router.hasRoute(cachedRoute)
          if (hasTheChild && isRouteExists) {
            route = cachedRoute
          }
        }
      }
      return {
        ...item,
        route,
        routeParams,
        active: currentParentKey.value === key,
      }
    }),
  )

  const getCurrentParent = computed(() =>
    getParentNavigation.value.find(item => item.active),
  )

  const getCurrentChildNavigation = computed(() =>
    getChildNavigation(currentParentKey.value),
  )

  const getBreadcrumbs = computed(() => {
    const result = []
    getCurrentParent.value && result.push(getCurrentParent.value.label)
    const child = getCurrentChild.value?.label
    child && result.push(child)
    return result
  })

  const getCurrentChild = computed(() => {
    if (currentParentKey.value === DASHBOARD_NAME) {
      const children = getChildNavigation(DASHBOARD_NAME)
      const child = children?.find(
        child =>
          child.route === route.name &&
          isEqual(child.routeParams, route.params),
      )
      return (
        child && {
          ...child,
          key: `${child.route}${JSON.stringify(child?.routeParams)}`,
        }
      )
    } else if (getCurrentParent.value?.label) {
      const children = CHILDREN_NAVIGATION[getCurrentParent.value.label]
      const child = children?.find(item =>
        findChildren(`${route.name?.toString()}`, item),
      )
      return child && { ...child, key: `${child.route}${JSON.stringify({})}` }
    }
    return undefined
  })

  // ACTIONS

  const findChildren = (name: string, child: NavigationPreset) =>
    child.route === name || child.additional?.includes(name)

  const getDashboardNavigation = computed(() => {
    return (dashboardSettingsStore.getDashboards?.map(item => ({
      label: item.name || 'Unnamed',
      route: ROUTE_NAME.DASHBOARDS_DASHBOARD,
      routeParams: { slug: item.slug },
      active: item.slug === route.params.slug,
      key: `${ROUTE_NAME.DASHBOARDS_DASHBOARD}${JSON.stringify({ slug: item.slug })}`,
      editable: true,
      update: (name: string) => {
        dashboardSettingsStore.saveDashboard({ ...item, name })
      },
      actions: [
        {
          tooltip: 'Customize',
          leading: Cog6ToothIcon,
          variant: 'gray',
          fill: 'light',
          action: ({ callback }) => {
            dashboardSettingsStore.setEditMode(true)
            router.push({
              name: ROUTE_NAME.DASHBOARDS_DASHBOARD,
              params: { slug: item.slug },
            })
            callback?.()
          },
        },
        {
          tooltip: 'Remove',
          leading: TrashIcon,
          variant: 'danger',
          fill: 'light',
          action: ({ callback, key }) => {
            if (!key) return
            const slug = JSON.parse(
              key.replace(ROUTE_NAME.DASHBOARDS_DASHBOARD, ''),
            )?.slug
            slug && dashboardSettingsStore.removeDashboard(slug)
            callback?.()
          },
        },
      ],
    })) || []) as NavigationItem[]
  })

  const getChildNavigation = (key: string): NavigationItem[] | undefined => {
    let result: NavigationItem[] = []
    switch (key) {
      case DASHBOARD_NAME:
        result = getDashboardNavigation.value
        break
      default:
        result = CHILDREN_NAVIGATION[key]?.map(item => {
          let isDirty
          switch (item.route) {
            case ROUTE_NAME.TRANSACTIONS:
              isDirty = transactionsBunchStore.isDirty
              break
            case ROUTE_NAME.ASSETS:
              isDirty = assetsBunchStore.isCommonDirty
              break
            case ROUTE_NAME.CURRENCIES:
              isDirty = assetsBunchStore.isCurrenciesDirty
              break
            case ROUTE_NAME.INCOME_ACCOUNTS:
              isDirty = assetsBunchStore.isIncomeAccountsDirty
              break
            case ROUTE_NAME.EXPENSE_ACCOUNTS:
              isDirty = assetsBunchStore.isExpenseAccountsDirty
              break
            case ROUTE_NAME.CONTACTS:
              isDirty = contactsBunchStore.isDirty
              break
            case ROUTE_NAME.DOCUMENTS:
              isDirty = documentsBunchStore.isDirty
              break
            case ROUTE_NAME.TAGS:
              isDirty = tagsBunchStore.isDirty
              break
          }
          return {
            ...item,
            key: `${item.route}${JSON.stringify({})}`,
            active:
              route.name === item.route ||
              item.additional?.includes(route.name as string),
            isDirty,
          }
        })
        break
    }

    return result
  }

  const clear = () => {
    currentParentKey.value = undefined
  }

  watch(
    route,
    ({ name: routeName, params: routeParams }) => {
      let currentKey
      let currentRouteName
      currentParentKey.value = undefined
      if (routeName === ROUTE_NAME.DASHBOARDS_DASHBOARD) {
        currentKey = DASHBOARD_NAME
        const children = getChildNavigation(DASHBOARD_NAME)
        const current = children?.find(
          child =>
            child.route === routeName &&
            isEqual(child.routeParams, routeParams),
        )
        if (current) {
          currentRouteName = JSON.stringify({
            name: current.route,
            params: current.routeParams,
          })
        }
      } else {
        const children = Object.entries(CHILDREN_NAVIGATION)
        currentKey = PARENT_NAVIGATION.find(
          item => item.route === routeName,
        )?.label
        if (!currentKey) {
          for (let i = 0; i < children.length; i++) {
            const [key, data] = children[i]
            const child = data.find(item =>
              findChildren(`${routeName?.toString()}`, item),
            )
            if (child) {
              currentKey = key
              currentRouteName = child.route
              break
            }
          }
        }
        if (!currentKey) return
      }
      currentParentKey.value = currentKey
      if (currentRouteName) {
        localStorage.setItem(`nav-${currentKey}`, currentRouteName)
        headerKeys[currentKey] = currentRouteName
      } else {
        localStorage.removeItem(`nav-${currentKey}`)
        headerKeys[currentKey] = undefined
      }
    },
    { immediate: true },
  )

  return {
    getParentNavigation,

    getChildNavigation,
    getCurrentParent,
    getCurrentChildNavigation,
    getCurrentChild,

    getBreadcrumbs,

    clear,
  }
})
