<template>
  <span class="main-desktop-tabs-primary-item-label">
    {{ item.label }}
    <span v-if="isDirty" class="main-desktop-tabs-dropdown-item-label__dirty">
      *
    </span>
  </span>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { NavigationItem } from '@types'

type Props = {
  item: NavigationItem
}

const props = defineProps<Props>()

const isDirty = computed(() => props.item?.isDirty)
</script>

<style>
.main-desktop-tabs-primary-item-label {
  @apply flex-auto;

  &__dirty {
    @apply text-yellow-500;
  }
}
</style>
