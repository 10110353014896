import { computed, ref } from 'vue'
import { defineStore } from 'pinia'
import { useRoute, useRouter } from 'vue-router'

import { DashboardsItem } from './utils/types'

import {
  DEFAULT_DESKTOP_DASHBOARDS,
  DEFAULT_MOBILE_DASHBOARDS,
} from './utils/const'
import { ROUTE_NAME } from '@/const'

import { useUISettings } from '@/hooks/uiSettings'

import { useResponsive } from '@/plugins/responsiveUI'

import { useRepositoriesStore } from '@/store/repositories'
import { useUserSubscriptionsStore } from '@/store/user/subscriptions'
import { useUserSettingsStore } from '@/store/user/settings'

export const useDashboardsSettingsStore = defineStore(
  'dashboards-settings',
  () => {
    // INIT
    const { isMobile, isTablet } = useResponsive()

    const userSettingsStore = useUserSettingsStore()
    const userSubscriptionsStore = useUserSubscriptionsStore()
    const repositoriesStore = useRepositoriesStore()

    const route = useRoute()
    const router = useRouter()

    const isEditMode = ref(false)

    const desktopDashboards = useUISettings<DashboardsItem[] | undefined>(
      ['dashboards'],
      undefined,
    )

    const appDashboards = useUISettings<DashboardsItem[] | undefined>(
      ['dashboards-app'],
      undefined,
    )

    const isMobileMode = computed(() => isMobile.value || isTablet.value)

    // GETTERS

    const getEditMode = computed(() => isEditMode.value)

    const getDashboards = computed(() => {
      if (!repositoriesStore.currentRepositoryId) return
      const plan = userSubscriptionsStore.getCurrentPlanName
      if (isMobileMode.value) {
        return appDashboards.value || DEFAULT_MOBILE_DASHBOARDS[plan]
      } else {
        return desktopDashboards.value || DEFAULT_DESKTOP_DASHBOARDS[plan]
      }
    })

    // SETTERS

    const setEditMode = (value: boolean) => {
      isEditMode.value = value
    }

    const setDashboards = (value: DashboardsItem[]) => {
      if (isMobileMode.value) {
        appDashboards.value = value
      } else {
        desktopDashboards.value = value
      }
    }

    // ACTIONS
    const saveDashboard = (data: DashboardsItem) => {
      getDashboards.value &&
        setDashboards(
          getDashboards.value.map(item =>
            item.slug === data.slug ? data : item,
          ),
        )
    }

    const addDashboard = (data: DashboardsItem) => {
      setDashboards([...(getDashboards.value || []), data])
    }

    const removeDashboard = (slug: string) => {
      const dashboards = getDashboards.value
      if (!dashboards) return
      const index = dashboards.findIndex(item => item.slug === slug)
      if (!~index) return
      dashboards.splice(index, 1)
      userSettingsStore.unsortTab(
        `${ROUTE_NAME.DASHBOARDS_DASHBOARD}${JSON.stringify({ slug })}`,
      )
      if (route.params.slug === slug) {
        const newSlug = dashboards[index - 1]?.slug
        if (newSlug) {
          router.push({
            name: ROUTE_NAME.DASHBOARDS_DASHBOARD,
            params: { slug: newSlug },
          })
        } else {
          router.push({ name: ROUTE_NAME.DASHBOARDS })
        }
      }
      setDashboards(dashboards)
    }

    const clear = () => {
      isEditMode.value = false
    }

    return {
      getEditMode,
      setEditMode,

      getDashboards,

      saveDashboard,
      addDashboard,
      removeDashboard,

      clear,
    }
  },
)
